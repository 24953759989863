import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addStory } from '../../actions/storyAction';
import modalCloseIcon from '../../images/modal-close.png';

const CreateStoryModule = ({ show, handleClose }) => {

    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const history = useHistory()
    const [courseName, setCourseName] = useState("")
    const [loader, setLoader] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            story_name: courseName
        }
        dispatch(addStory(auth.token, data, setLoader, history, handleClose))
    }

    return (
        <Modal className="VideoModal small" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <label>Enter The Story Name</label>
                        <input
                            type="text"
                            className="inpField-inp"
                            placeholder="Story Name"
                            onChange={(e) => setCourseName(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">Next {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateStoryModule