import React, { useState, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab';
import Sonority from './Sonority';
import BgMusic from './BgMusic';
import Recording from './Recording';
import UploadAudio from './UploadAudio';
import VoiceOver from './VoiceOver';
import { useSelector } from 'react-redux';

const Audio = () => {
    const story = useSelector(state => state.story)
    const auth = useSelector(state => state.auth)
    const selectedSlide = story.slides.find(({ isSelected }) => isSelected === true)
    const selectedSlideIndex = story.slides.findIndex(({ isSelected }) => isSelected === true)

    const [membership, setMembership] = useState([])


    useEffect(() => {
        if (auth.user) {
            setMembership(auth.user.membership.split("__"))
        }
    }, [auth.user])
    return (
        <Tab.Pane eventKey="audio">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav className="colNav colNavDivide">
                    <Nav.Item>
                        <Nav.Link eventKey="first">AI Voices</Nav.Link>
                    </Nav.Item>
                    {membership.includes("sonority") || membership.includes("bundle") ?
                        <Nav.Item>
                            <Nav.Link eventKey="third">Sonority</Nav.Link>
                        </Nav.Item> : ""}
                    <Nav.Item>
                        <Nav.Link eventKey="fourth">Recording</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="fifth">Background Music</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second">Upload Voiceover</Nav.Link>
                    </Nav.Item>
                </Nav>


                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <VoiceOver
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                        <UploadAudio
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                    </Tab.Pane>
                    {membership.includes("sonority") || membership.includes("bundle") ?
                        <Tab.Pane eventKey="third">
                            <Sonority
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}
                            />
                        </Tab.Pane> : ""}
                    <Tab.Pane eventKey="fourth">
                        <Recording
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                        <BgMusic
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                    </Tab.Pane>
                </Tab.Content>


            </Tab.Container>
        </Tab.Pane>
    )
}

export default Audio