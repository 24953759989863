import React, { useState, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { getTempData, updateTemplate } from '../../../../actions/storyAction';

const InBuiltTemplates = ({ selectedSlideIndex }) => {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [tempData, setTempData] = useState([])
    const [membership, setMembership] = useState([])
    const [state, setState] = useState({
        activeIndex: false,
        searchKey: ""
    })
    const fetchTemplates = () => {
        dispatch(getTempData(setTempData, false, membership))
    }

    const handleChange = (e) => {
        setState({
            ...state,
            searchKey: e.target.value
        })
    }

    const handleSelectTemp = (tempData, index) => {
        setState({
            ...state,
            activeIndex: index
        })
        dispatch(updateTemplate(tempData, selectedSlideIndex, "inbuilt"))
    }



    useEffect(() => {
        if (auth.user) {
            setMembership(auth.user.membership.split("__"))
        }
    }, [auth.user])


    useEffect(() => {
        if (membership.length > 0) {
            fetchTemplates()
        }
    }, [membership])
    return (
        <Tab.Pane eventKey="inTemp">
            <div className="imgOpt">
                <div className="imageSearch">
                    <input
                        type="text"
                        placeholder="Search Templates"
                        onChange={handleChange}
                    />
                </div>
                <div className="scrollDiv scrollDiv-3">
                    <div className="imageSelect">
                        <ul >
                            {tempData.length > 0 ?
                                tempData.filter((item) => {
                                    if (state.searchKey !== "") {
                                        return item.name.toLowerCase().includes(state.searchKey.toLowerCase())
                                    }
                                    else {
                                        return item
                                    }
                                }).map((curElem, index) => {
                                    return (
                                        <>
                                            <li
                                                key={index}
                                                onClick={() => handleSelectTemp(curElem, index)}
                                                className="cursor-pointer"
                                                title={curElem.name}
                                            >
                                                <div className="imageSelect-single">
                                                    <img src={curElem.preview} alt={curElem.name} />
                                                    {
                                                        state.activeIndex === index ?
                                                            <div className="slide-active">
                                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                            </div>

                                                            : ''
                                                    }
                                                </div>


                                            </li>
                                        </>

                                    )
                                })
                                : ""}
                        </ul>
                        {tempData.length > 0 ? "" :
                            <div className='text-center'>
                                No Data Found
                            </div>}
                    </div>
                </div>
            </div>
        </Tab.Pane>
    )
}

export default InBuiltTemplates