import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { endAnimation, updateLayerStatus } from '../../actions/storyAction'
import { Rnd } from "react-rnd";
import { shapesArr } from '../../fonts/IconsFile'


const PollsLayer = (props) => {
    const dispatch = useDispatch()
    let dataVal = props.layer.src
    if (props.layer.type === "shape") {
        let tempData = shapesArr.find(({ name }) => name === props.layer.src)
        dataVal = tempData.shape
    }

    const [style, setStyle] = useState({
        color: props.layer.style.color,
        fontSize: props.layer.style.fontSize,
        fontWeight: props.layer.style.fontWeight,
        fontFamily: props.layer.style.fontFamily,
        fontStyle: props.layer.style.fontStyle,
        lineHeight: props.layer.style.lineHeight,
        textTransform: props.layer.style.textTransform,
        textDecoration: props.layer.style.textDecoration,
        background: props.layer.style.background ? props.layer.style.background : props.layer.style.backgroundColor,
        textAlign: props.layer.style.textAlign,
        opacity: props.layer.style.opacity,
        border: props.layer.style.border,
        boxShadow: props.layer.style.boxShadow,
        width: props.layer.size.width,
        height: props.layer.size.height,
        top: props.layer.position.top,
        left: props.layer.position.left,
        animationDuration: props.layer.animation.duration,
        animationDelay: props.layer.animation.delay,
        position: "absolute",
    })

    useEffect(() => {
        setStyle({
            ...style,
            color: props.layer.style.color,
            fontSize: props.layer.style.fontSize,
            fontWeight: props.layer.style.fontWeight,
            fontFamily: props.layer.style.fontFamily,
            fontStyle: props.layer.style.fontStyle,
            lineHeight: props.layer.style.lineHeight,
            textTransform: props.layer.style.textTransform,
            textDecoration: props.layer.style.textDecoration,
            background: props.layer.style.background ? props.layer.style.background : props.layer.style.backgroundColor,
            textAlign: props.layer.style.textAlign,
            opacity: props.layer.style.opacity,
            border: props.layer.style.border,
            boxShadow: props.layer.style.boxShadow,
            width: props.layer.size.width,
            height: props.layer.size.height,
            top: props.layer.position.top,
            left: props.layer.position.left,
            animationDuration: props.layer.animation.duration,
            animationDelay: props.layer.animation.delay,
            position: "absolute",
        })
    }, [props.layer.style, props.layer.position, props.layer.size, props.layer.animation])

    let localState = {
        animationDuration: style.animationDuration,
        animationDelay: style.animationDelay,
        position: "absolute",
        overflow: "hidden",
        border: props.layer.style.border,
        boxShadow: props.layer.style.boxShadow,
    }

    const questionStyle = {
        color: style.color,
        fontFamily: style.fontFamily,
        fontSize: parseInt(style.fontSize + 8),
        textAlign: style.textAlign,
        fontWeight: style.fontWeight,
        fontStyle: style.fontStyle,
        textTransform: style.textTransform,
        textDecoration: style.textDecoration,
        lineHeight: style.lineHeight,
    }
    const optionStyle = {
        background: style.background,
        color: style.color,
        fontSize: parseInt(style.fontSize),
        fontStyle: style.fontStyle,
        textTransform: style.textTransform,
        textDecoration: style.textDecoration,
        lineHeight: style.lineHeight,
        fontFamily: style.fontFamily,
    }

    const handleClick = (layerIndex) => {
        dispatch(updateLayerStatus(layerIndex, props.selectedSlideIndex))
    }

    const handleAnimEnd = () => {
        dispatch(endAnimation(props.selectedSlideIndex, props.index))
    }

    return (
        <Rnd
            key={props.index}
            style={{ boxShadow: style.boxShadow, }}
            className={`${props.layer.isSelected ? "myborder-layer forPoll" : ""} text-layer-box`}
            enableResizing={props.layer.isSelected ? true : false}
            disableDragging={props.layer.isSelected ? false : true}
            onClick={() => handleClick(props.index)}
            default={{ x: 0, y: 0, width: 100, height: 100 }}
            size={{ width: style.width, height: style.height }}
            position={{ x: style.left, y: style.top }}
            onDragStop={(e, d) => {
                setStyle({ ...style, left: d.x, top: d.y })
                props.handleRePosition(d.y, d.x, props.index)
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                setStyle(
                    {
                        ...style,
                        width: ref.style.width,
                        height: ref.style.height,
                        top: position.y,
                        left: position.x
                    }
                );
                props.handleResize(ref.style.width, ref.style.height, position.x, position.y, props.index)
            }}
            bounds=".vid-poster"
        >
            <div
                key={props.index}
                className={`${props.layer.animation.playAnimation ? props.layer.animation.name : ""} animate__animated css-for-svgs `}
                style={localState}
                onAnimationEnd={() => handleAnimEnd()}

            >
                <div className='pollIn p-2'>
                    <div className='pollIn-single'>
                        <h2 style={questionStyle}>
                            {props.layer.src.question}
                        </h2>
                    </div>

                    {
                        props.layer.src.option1 !== "" ?
                            <>
                                <div className='pollIn-single label' style={optionStyle}>
                                    <input
                                        type="radio"
                                        name='selectedOption'
                                        value={props.layer.src.option1}

                                    />
                                    <span className='radioCheck' style={{ background: optionStyle.background }}></span>
                                    <label
                                        style={{ color: optionStyle.color }}
                                    >{props.layer.src.option1}</label>
                                </div>
                            </>
                            : ""
                    }
                    {
                        props.layer.src.option2 !== "" ?
                            <>
                                <div className='pollIn-single label' style={optionStyle}>
                                    <input
                                        type="radio"
                                        name='selectedOption'
                                        value={props.layer.src.option2}

                                    />
                                    <span className='radioCheck' style={{ background: optionStyle.background }}></span>
                                    <label
                                        style={{ color: optionStyle.color }}
                                    >{props.layer.src.option2}</label>
                                </div>
                            </>
                            : ""
                    }
                    {
                        props.layer.src.option3 !== "" ?
                            <>
                                <div className='pollIn-single label' style={optionStyle}>
                                    <input
                                        type="radio"
                                        name='selectedOption'
                                        value={props.layer.src.option3}
                                        style={optionStyle}
                                    />
                                    <span className='radioCheck' style={{ background: optionStyle.background }}></span>
                                    <label
                                        style={{ color: optionStyle.color }}
                                    >{props.layer.src.option3}</label>
                                </div>
                            </>
                            : ""
                    }
                    {
                        props.layer.src.option4 !== "" ?
                            <>
                                <div className='pollIn-single label' style={optionStyle}>
                                    <input
                                        type="radio"
                                        name='selectedOption'
                                        value={props.layer.src.option4}
                                        style={optionStyle}
                                    />
                                    <span className='radioCheck' style={{ background: optionStyle.background }}></span>
                                    <label
                                        style={{ color: optionStyle.color }}
                                    >{props.layer.src.option4}</label>
                                </div>
                            </>
                            : ""
                    }
                </div>

            </div>
        </Rnd>
    )
}

export default PollsLayer