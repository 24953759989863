import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import DashboardAbout from "./DashboardAbout";
import Webinar from "./Webinar";
import TitleBar from "../TitleBar";
import { useEffect } from "react";
import { loadUser } from "../../actions/authAction";
import { useDispatch } from "react-redux";


const Dashboard = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(loadUser())
    }, [])
    return (
        <>
            <TitleBar title="Dashboard" />
            <Navbar />
            <section className="siteWrap">
                <DashboardHead />
                <DashboardContent />
                <DashboardAbout />
            </section>
            <Webinar />
            <Footer />
        </>
    )
}

export default Dashboard;