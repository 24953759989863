import React, { useState , useCallback} from 'react'
import Tab from 'react-bootstrap/Tab';
import { setAlert } from '../../../../actions/alert';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { commonAxios } from '../../../../global/CommonAxios';
import { addLayerMedia, generateFile, updateBgMedia, updateLayerMedia } from '../../../../actions/storyAction';
import Cropper from 'react-easy-crop';
import getCroppedImg from "./CropImage";

const Image = ({ selectedSlide, selectedSlideIndex, type, selectedLayer, selectedLayerIndex }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false);
    const [imageData, setImageData] = useState([]);
    const [cropModel, setCropModel] = useState(false);
    const [currentImage, setCurrentImage] = useState(false);
    const [outputImage, setOutputImage] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [searchImage, setSearchImage] = useState({
        keyword: 'nature',
        search_type: 'image',
        page: 1,
        selectedImgIndex: -1
    });

    const fetchImage = (page = 1) => {
        setLoader(true);
        let data = {
            keyword: searchImage.keyword,
            type: searchImage.search_type,
            page_number: page
        }

        commonAxios("load-library-images", data, dispatch, auth.token)
            .then((res) => {
                if (res.status) {
                    let arr = [...imageData];
                    setImageData(arr.concat(res.data));
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setLoader(false);
                setSearchImage({ ...searchImage, page: page });
            }).catch((err) => {
                setLoader(false);
                console.log(err)
            })
    }

    const onInputChange = (e) => {
        setSearchImage({ ...searchImage, keyword: e.target.value });
        setImageData([])
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        fetchImage();
    }

    const selectCrop = (url) => {
        setCropModel(true);
        setCurrentImage(url);
        setOutputImage(false)
    }

    const selectCropImage = (image) => {
        let objData = {
            url: image,
            mediaType: "image"
        }
        if (type === "background") {
            dispatch(updateBgMedia(objData, selectedSlideIndex))
        } else {
            if (selectedSlide.layers.length > 0) {
                if (selectedLayerIndex !== -1 && selectedLayer.type !== "text") {
                    dispatch(updateLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                } else {
                    dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                }
            }
            else {
                dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
            }
        }

        setCropModel(false);
        setOutputImage(false);
    }

    const showCroppedImage = useCallback(async () => {
        setCropLoader(true);
        try {
            const croppedImage = await getCroppedImg(
                currentImage,
                croppedAreaPixels,
                rotation
            )
            downloadFiles(croppedImage);
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])


    const downloadFiles = (imageData) => {
        let ext = currentImage.match(/\.([^\./\?]+)($|\?)/)[1];
        setCropLoader(true);
        let data = JSON.stringify({ data: imageData, format: ext })
        dispatch(generateFile(data, setOutputImage, setCropLoader))
    }
    const handleSelectImage = (curElem, index) => {
        setSearchImage({
            ...searchImage,
            selectedImgIndex: index
        })
        let objData = {
            url: curElem.url,
            mediaType: "image"
        }
        if (type === "background") {
            dispatch(updateBgMedia(objData, selectedSlideIndex))
        } else {
            if (selectedSlide.layers.length > 0) {
                if (selectedLayerIndex !== -1 && selectedLayer.type !== "text") {
                    dispatch(updateLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                } else {
                    dispatch(addLayerMedia(objData, selectedSlideIndex))
                }
            }
            else {
                dispatch(addLayerMedia(objData, selectedSlideIndex))
            }
        }
    }

    return (
        <Tab.Pane eventKey="imAge">
            <div className="imgOpt">
                <form
                    className="imageSearch"
                    style={{ position: "relative" }}
                    onSubmit={(e) => onFormSubmit(e)}
                >
                    <input
                        type="text"
                        placeholder="Search Images"
                        onInput={(e) => onInputChange(e)}
                    />
                    <span
                        type="submit"
                        className="search-btn"
                    ><i class="fa fa-search" aria-hidden="true" onClick={() => { fetchImage() }}></i></span>
                </form>

                <div className="scrollDiv scrollDiv-3" id={`scrollableImage${type}`}>
                    <div className="imageSelect" >
                        <ul>
                            <InfiniteScroll
                                dataLength={imageData.length}
                                next={() => {
                                    fetchImage(searchImage.page + 1)
                                }}
                                hasMore={true}
                                scrollableTarget={`scrollableImage${type}`}
                            >
                                {
                                    imageData.length > 0 ?
                                        imageData.map((curElem, index) => {
                                            return (
                                                <li className='col-md-6 cursor-pointer' key={index}>
                                                    <div className="imageSelect-single">
                                                        <img
                                                            src={curElem.thumbnail}
                                                            alt={`${searchImage.keyword + " " + index}`}
                                                            onClick={() => handleSelectImage(curElem, index)}
                                                        />
                                                        {searchImage.selectedImgIndex === index ?
                                                            <div className="slide-active">
                                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                            </div>
                                                            : ''
                                                        }
                                                        <div className="crop-icon"
                                                            onClick={() => selectCrop(curElem.url)}
                                                        >
                                                            <i className="fa fa-crop"></i>
                                                        </div>
                                                    </div>
                                                </li>

                                            )
                                        })
                                        :
                                        ''
                                }


                            </InfiniteScroll>

                        </ul>
                        {
                            loader ?
                                <div className="col-md-12">
                                    <h4 className='text-center'>
                                        <i className="fa fa-spinner fa-spin" style={{ color: "#fbd90e" }} />
                                    </h4>
                                </div>
                                : ''
                        }
                    </div>
                </div>

                <div className={`modal VideoModal ${cropModel ? 'show-modal' : ''} `} style={{}}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header crop-header">
                                <h5 className="modal-title text-center">Crop Image</h5>
                                <button onClick={(e) => setCropModel(false)} type="button" className="close img-close" data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true"><i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ position: "relative", height: "50vh" }}>
                                <Cropper
                                    image={currentImage}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={9 / 16}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    objectFit="contain"
                                />
                                {
                                    croppedAreaPixels === null ? <div className="crop-icon-center"><i className="fa fa-spinner fa-spin mr-2" style={{ color: "#F68802", fontSize: '25px' }} /></div> : ''
                                }

                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <button className="btn-custom mr-1" type="button" onClick={() => showCroppedImage()}>
                                    {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                    {cropLoader ? 'Cropping' : 'Crop'}
                                </button>
                                <button
                                    className={`${outputImage === false ? 'btn-custom-disable' : 'btn-custom'}`}
                                    type="button"
                                    disabled={outputImage === false ? true : false}
                                    onClick={() => selectCropImage(outputImage)}
                                >
                                    Use
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane >
    )
}

export default Image