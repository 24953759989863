import React from 'react'
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addLayerMedia, searchGifData, updateBgMedia, updateLayerMedia } from '../../../../actions/storyAction';

const Gifs = ({ selectedSlide, selectedSlideIndex, type, selectedLayer, selectedLayerIndex }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [searchKey, setSearchKey] = useState("")
    const [loader, setLoader] = useState("")
    const [gif, setGif] = useState([])
    const [state, setState] = useState({
        selectedGif: -1,
        searchKey: ""
    })
    const fetchGifs = (e) => {
        e.preventDefault()
        let data = {
            types: searchKey
        }
        setGif([])
        setLoader(true)
        dispatch(searchGifData(data, auth.token, setLoader, setGif))
    }

    const handleGif = (url, index) => {
        setState({
            ...state,
            selectedGif: index
        })
        let objData = {
            url: url,
            mediaType: "video"
        }
        if (type === "background") {
            dispatch(updateBgMedia(objData, selectedSlideIndex))
        } else {
            if (selectedSlide.layers.length > 0) {
                if (selectedLayerIndex !== -1 && selectedLayer.type !== "text") {
                    dispatch(updateLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                } else {
                    dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
                }
            } else {
                dispatch(addLayerMedia(objData, selectedSlideIndex, selectedLayerIndex))
            }
        }
    }
    return (

        <Tab.Pane eventKey="giFs">
            <div className="imgOpt">
                <form className="imageSearch" onSubmit={fetchGifs}>
                    <input
                        type="text"
                        placeholder="Search GIFs"
                        onChange={(e) => setSearchKey(e.target.value)}
                    />
                </form>
                <div className="scrollDiv scrollDiv-3">
                    <div className="imageSelect">
                        <ul>
                            {gif.length > 0 ?
                                gif.map((curElem, index) => {
                                    return (
                                        <li
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleGif(curElem.images.original.mp4, index)}
                                        >
                                            <div
                                                className="imageSelect-single forGif"
                                                style={{ border: state.selectedGif === index ? "1px solid #fbd90e" : "" }}
                                            >
                                                <img src={curElem.images.original.url} alt=""/>
                                            </div>
                                        </li>

                                    )
                                }) : ""
                            }
                        </ul>
                        {loader ?
                            <div className='text-center'>
                                <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#fbd90e", fontSize: "25px" }} />
                            </div> : ""}
                    </div>
                </div>
            </div>
        </Tab.Pane>
    )
}

export default Gifs