import React, {useState} from "react";
import {useSelector} from "react-redux";
import queryString from "query-string";
import {Link} from "react-router-dom";

const TrainingTitles = ({location, id, name}) => {

    const supportData = useSelector(state => state.support);
    const articleId = queryString.parse(location.search).id;

    const [articles, setArticles] = useState(supportData.articles);
    const currentArt = supportData.articles.filter((article)=> article.id === id);

    return(
        <>
            <div className="container-fluid mt-5 ml-2">
                <div className="row">
                    <div className="col-12">
                        <div className="mb-2 support-data text-white">
                            <Link to="/help-and-support" className="support-link text-white">Support </Link>
                            /
                            <Link className={`${name ? '' : 'active-help'} support-link`} to={`training?id=${currentArt[0].id}`}> {currentArt[0].name} </Link>
                            {
                                name ? <> / <span className="active-help">{name.title}</span></> : ''
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 tabLeft">
                <div className="popular-topics-box">
                    <h5 className="text-white pb-4">POPULAR TOPICS</h5>

                    <div className="tabCont-left">

                        <div className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            {
                                articles.length > 0 ?
                                    articles.map((item,index)=>{
                                        return(
                                            <Link key={index} className={`nav-link ${articleId === item.id ? 'active' : '' }`} to={`/training?id=${item.id}`} >
                                                {item.name}
                                            </Link>
                                        )
                                    })
                                : ''
                            }
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default TrainingTitles;