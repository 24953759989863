import React, { useState } from "react";
import TitleBar from "../TitleBar";
import Header from "../CreateVideos/Header";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import PublisherDetails from "./PublisherDetails";
import Seo from "./Seo";
import { useSelector } from "react-redux";
import { updateAnalyticText } from "../../actions/publishAction";
import { useDispatch } from "react-redux";
import PublishWithDomain from "./PublishWithDomain";

const Publish = () => {
  const dispatch = useDispatch()
  const story = useSelector(state => state.story)
  const [analytics, setAnalytics] = useState("")

  const handleChange = (e) => {
    setAnalytics(e.target.value)
    dispatch(updateAnalyticText(e.target.value))
  }
  return (
    <>
      <TitleBar title="Publish" />
      <div className="editorWrap editorWrap-alt">
        <div className="publish-head">
          <Header
            name={story.name}
          />
        </div>
        <div className="Mainareaalt">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">

            <Nav className="colNav colNav-center">
              <Nav.Item>
                <Nav.Link eventKey="first"><span>1</span> Publisher Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second"><span>2</span> SEO</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third"><span>3</span> Analytics & Ads</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth"><span>4</span> Publish</Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-9">

                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <PublisherDetails />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Seo />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="formWrap">
                        <div className="profileBlock">
                          <div className="form-group form-group-alt mt-0">
                            <label>Analytics</label>
                            <textarea
                              className="form-control forTextr"
                              placeholder="Analytics"
                              name="analytics"
                              analytics={analytics}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <PublishWithDomain
                        id={story.id} />
                    </Tab.Pane>
                  </Tab.Content>

                </div>
              </div>

            </div>
          </Tab.Container>
        </div>

      </div>
    </>
  )
}

export default Publish;