import { commonAxios } from "../global/CommonAxios";
import { setAlert } from "./alert";

export const publishTitle = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_PUBLISH_STORY_TITLE", payload: data });
};

export const publishLanguage = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_PUBLISH_STORY_LANGUAGE", payload: data });
};
export const publishName = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_PUBLISH_STORY_NAME", payload: data });
};

export const uploadPublishMedia =
  (formData, setLoader, setPercent, state, setState, type) =>
  (dispatch, getState) => {
    const config = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setPercent(percent);
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: getState().auth.token,
      },
    };
    commonAxios("upload-file", formData, dispatch, false, config)
      .then((res) => {
        if (res.status) {
          if (type === "logo") {
            setState({
              ...state,
              logo: res.data,
            });
            dispatch({ type: "UPDATE_LOGO_IMAGE", payload: res.data.path });
          }
          if (type === "favicon") {
            setState({
              ...state,
              favIcon: res.data,
            });
            dispatch({ type: "UPDATE_FAV_IMAGE", payload: res.data.path });
          }
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setPercent(0);
        setLoader(false);
      })
      .catch((err) => {
        setPercent(0);
        setLoader(false);
        console.log(err);
      });
  };

export const updateSeoTitle = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_SEO_TITLE", payload: data });
};
export const updateSeoKey = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_SEO_KEY", payload: data });
};
export const updateSeoDesc = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_SEO_DESC", payload: data });
};
export const updateSeoImgUrl = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_SEO_IMGURL", payload: data });
};

export const updateAnalyticText = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_ANALYTICS_TEXT", payload: data });
};

export const checkStoryDomain =
  (data, checkDomain, setCheckDomain) => (dispatch, getState) => {
    commonAxios("addon-domain-add", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          setCheckDomain({
            ...checkDomain,
            active: true,
            loader: false,
          });
          dispatch({ type: "UPDATE_DOMAIN", payload: data });
        } else {
          setCheckDomain({
            ...checkDomain,
            active: false,
            loader: false,
          });
          dispatch(setAlert(res.msg, "danger"));
        }
      })
      .catch((err) => {
        setCheckDomain({
          ...checkDomain,
          loader: false,
        });
        console.log(err);
      });
  };

export const onFecthDomain = (setState) => (dispatch, getState) => {
  commonAxios("user-domain-list", {}, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setState(res.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const publishData =
  (data, id, setLoader, show) => (dispatch, getState) => {
    if (show) {
      setLoader(true);
    }
    data.publish = {
      ...data.publish,
      storyId: id,
    };
    commonAxios("publish", data, dispatch, getState().auth.token)
      .then((res) => {
        if (res.status) {
          if (show) {
            dispatch(setAlert(res.msg, "success"));
            window.open(res.data.url, "_blank");
          }
        } else {
          dispatch(setAlert(res.msg, "danger"));
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

export const publishSelectDomain = 
(id,setLoader,show,setDataDom,dataDom) => (dispatch, getState) => {
  commonAxios("user-domain-list", id, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        console.log((res.data), "vivek");
        setDataDom(res.data)
        //console.log(dataDom,"data")
       
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
