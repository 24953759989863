import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Emojis from './Emojis';
import Shapes from './Shapes';
import Borders from './Borders';

const Elements = ({ selectedSlide, selectedSlideIndex }) => {
    return (
        <Tab.Pane eventKey="eleMents">
            <Tab.Container defaultActiveKey="elemTab-1">
                <Nav className="colNav">
                    <Nav.Item>
                        <Nav.Link eventKey="elemTab-1">Elements</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link eventKey="elemTab-2">Shapes</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="elemTab-3">Borders</Nav.Link>
                    </Nav.Item> */}
                </Nav>
                <Tab.Content>
                    <Emojis
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                    />
                    {/* <Shapes
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                    />
                    <Borders
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                    /> */}
                </Tab.Content>
            </Tab.Container>
        </Tab.Pane>
    )
}

export default Elements