import axios from 'axios';
import React, { useState, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import { SketchPicker } from 'react-color';
import Autocomplete from "react-google-autocomplete";
import { CgAlignBottom, CgAlignCenter, CgAlignLeft, CgAlignMiddle, CgAlignRight, CgAlignTop } from "react-icons/cg";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import WebFont from 'webfontloader';
import { addBorder, addBorderSize, fetchFonts, textLayerAdd, textLayerUpdate, updateAddLocation, updateFontFamily, updateFontSize, updateTextAlign, updateTextBold, updateTextItalic, updateTextLineHeight, updateTextLower, updateTextStrike, updateTextUnderline, updateTextUpper } from '../../../actions/storyAction';
import ColorPattern from './ColorPattern';
import Filters from './Filters';
import LayerLink from './LayerLink';
import Poll from './Poll';
import ShadowBorder from './ShadowBorder';

const Customization = ({ slides, selectedSlide, selectedSlideIndex }) => {
    const auth = useSelector(state => state.auth)
    const GOOGLE_MAPS_API_KEY = process.env.React_App_GOOGLE_MAPS_API_KEY
    const dispatch = useDispatch();
    const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
        , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
        , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
        , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]

    let allowType = ["text", "icon", "shape", "location", "button", "polls"]
    let allowType1 = ["image", "video", "gif", "emoji"]
    const [fonts, setFonts] = useState([])
    const [googleFonts, setGoogleFonts] = useState({ data: [] });
    const [selectedLayerIndex, setSelectedLayerIndex] = useState(-1)
    const [selectedText, setSelectedText] = useState(false)
    const [selectedTextIndex, setSelectedTextIndex] = useState(false)
    const [layerText, setLayerText] = useState("")
    const [layerType, setLayerType] = useState("")
    const [membership, setMembership] = useState([])

    const [styleState, setStyleState] = useState({
        color: "#ffffff",
        fontFamily: "",
        backgroundColor: "",
        fontSize: "36",
        textAlign: "center",
        fontWeight: "",
        fontStyle: "",
        textTransform: "lowercase",
        textDecoration: "none",
        lineHeight: 1,
        opacity: 1,
    })
    const [border, setBorder] = useState({
        borderSize: 0,
        borderColor: "#000000",
        shadowColor: "#000000"
    })

    useEffect(() => {
        if (selectedText !== false && selectedText !== undefined) {
            if (selectedText.isSelected === true) {
                setStyleState((selectedText.style))
            }
        }
    })

    useEffect(() => {
        if (selectedText) {
            if (selectedText.type === "text" || selectedText.type === "location" || selectedText.type === "button") {
                setLayerText(selectedText.src)
            }
        }
    },[selectedText])

    useEffect(() => {
        if (auth.user) {
            setMembership(auth.user.membership.split("__"))
        }
    }, [auth.user])


    const onTextAlign = (align) => {
        if (selectedText) {
            dispatch(updateTextAlign(align, selectedSlideIndex, selectedTextIndex, selectedSlide.type));
        }
    }


    const onChangeLineHeight = (e) => {
        if (selectedText) {
            dispatch(updateTextLineHeight(e.target.value, selectedSlideIndex, selectedTextIndex, selectedSlide.type))
        }
    }


    const onTextStyle = (type) => {
        if (selectedText) {
            if (type === 'bold') {
                if (styleState.fontWeight === "") {
                    dispatch(updateTextBold("bold", selectedSlideIndex, selectedTextIndex));
                } else {
                    dispatch(updateTextBold("", selectedSlideIndex, selectedTextIndex));
                }
            } else if (type === 'italic') {
                if (!styleState.fontStyle) {
                    dispatch(updateTextItalic("italic", selectedSlideIndex, selectedTextIndex));
                } else {
                    dispatch(updateTextItalic("", selectedSlideIndex, selectedTextIndex));
                }
            } else if (type === 'uppercase') {
                if (styleState.textTransform === "uppercase") {
                    dispatch(updateTextUpper("none", selectedSlideIndex, selectedTextIndex));
                } else {
                    dispatch(updateTextUpper("uppercase", selectedSlideIndex, selectedTextIndex));
                }
            } else if (type === 'lowercase') {
                if (styleState.textTransform === "lowercase") {
                    dispatch(updateTextLower("none", selectedSlideIndex, selectedTextIndex));
                } else {
                    dispatch(updateTextLower("lowercase", selectedSlideIndex, selectedTextIndex));
                }
            } else if (type === 'underline') {
                if (styleState.textDecoration === "underline") {
                    dispatch(updateTextUnderline("none", selectedSlideIndex, selectedTextIndex));
                } else {
                    dispatch(updateTextUnderline("underline", selectedSlideIndex, selectedTextIndex));
                }
            } else if (type === 'strike') {
                if (styleState.textDecoration === "line-through") {
                    dispatch(updateTextStrike("none", selectedSlideIndex, selectedTextIndex));
                } else {
                    dispatch(updateTextStrike("line-through", selectedSlideIndex, selectedTextIndex));
                }
            }
        }
    }


    const onInputChange = (e, type) => {
        if (selectedText) {
            if (type === 'size') {
                dispatch(updateFontSize(e.target.value, selectedSlideIndex, selectedTextIndex));
            } else if (type === 'font_family') {
                dispatch(updateFontFamily(e.target.value, selectedSlideIndex, selectedTextIndex));
            }
        }
    }

    const handleChangeText = (text) => {
        if (selectedText.type === "text" || selectedText.type === "location" || selectedText.type === "button" && selectedText) {
            setLayerText(text)
            dispatch(textLayerUpdate(text, selectedSlideIndex, selectedTextIndex))
        }
    }

    const onSelectBorder = (val) => {
        if (parseInt(val) < 0) {
            setBorder({
                ...border,
                borderSize: 0
            })
        }
        else {
            let isSelectedLayer = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
            if (isSelectedLayer !== -1) {
                let borderData = `${val}px solid ${border.borderColor}`
                if (val === "") {
                    borderData = ""
                }
                if (selectedSlide.layers.length > 0) {
                    setBorder({
                        ...border,
                        borderSize: val
                    })
                    dispatch(addBorder(borderData, selectedSlideIndex, isSelectedLayer))
                }
            }
        }
    }



    const updateLocation = (place) => {
        dispatch(updateAddLocation(place.formatted_address, selectedSlideIndex, selectedTextIndex))
    }

    useEffect(() => {
        if (selectedSlide) {
            let val = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
            let val2 = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
            setSelectedLayerIndex(val2)
            if (val) {
                if (allowType.includes(val.type)) {
                    setSelectedText(val)
                    setSelectedTextIndex(selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true))

                }
                else {
                    let ifTextLayer = selectedSlide.layers.find(({ type }) => type === "text")

                    if (ifTextLayer) {
                        setSelectedText(ifTextLayer)
                    }
                    else {
                        setLayerText("")
                    }
                }
                setLayerType(val.type)
            } else {
                if (selectedSlide.layers.length === 0) {
                    setSelectedText(false)
                    setSelectedTextIndex(-1)
                    setLayerText("")
                }
                setLayerType("")
            }
        }
    }, [slides, selectedSlideIndex, selectedSlide.layers])

    useEffect(() => {
        if (membership.length > 0) {
            dispatch(fetchFonts(setGoogleFonts, googleFonts, setFonts, fontNotSet, membership))
        }
    }, [membership])

    useEffect(() => {
        if (googleFonts.data.length > 0) {
            WebFont.load({
                google: {
                    families: googleFonts.data
                }
            });
        }
    }, [googleFonts.data])

    return (
        <Tab.Pane eventKey="designTab">
            {selectedLayerIndex !== -1 ?
                <div className="tabWrap scrollDiv scrollDiv-6">
                    {layerType === "text" || layerType === "location" || layerType === "button" ?
                        < div className="select-your-color pt-0">
                            <div className="select-head d-flex justify-content-between">
                                <p className="innerHead">{selectedText.type === "location" ? "Location" : "Text"}</p>
                            </div>
                            <div className="select-your-color-opt">
                                {selectedText.type === "location" ?
                                    <Autocomplete
                                        apiKey={GOOGLE_MAPS_API_KEY}
                                        className="inpField"
                                        onPlaceSelected={(place) => updateLocation(place)}
                                        defaultValue={layerType === "location" ? "" : layerText}
                                    />
                                    :
                                    <textarea
                                        className="inpField"
                                        value={layerText ? layerText : ''}
                                        onChange={(e) => handleChangeText(e.target.value)}
                                    ></textarea>
                                }
                            </div>
                        </div> : ""}
                    {
                        layerType === "polls" ?
                            <Poll
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}
                                selectedText={selectedText}
                            />

                            : ""}


                    {allowType.includes(layerType) ?
                        <>

                            <div className="select-your-color">
                                <p className="innerHead">Font Style</p>
                                <div className="select-your-color-opt">
                                    <div className='row row-alt'>
                                        <div className='col-9'>
                                            <select
                                                className="inpField"
                                                name="font_family"
                                                id="sel-font"
                                                onChange={(e) => onInputChange(e, 'font_family')}
                                            >
                                                <option value={""}>Select Font</option>
                                                {
                                                    fonts.length > 0 ?
                                                        fonts.map((font, index) => {
                                                            return (
                                                                <option
                                                                    style={{ fontFamily: font.value }}
                                                                    selected={font.value === styleState.fontFamily}
                                                                    value={font.value}
                                                                    key={index}
                                                                >
                                                                    {font.name}
                                                                </option>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </select>
                                        </div>
                                        <div className='col-3'>
                                            <input
                                                type="number"
                                                min={1}
                                                value={styleState.fontSize}
                                                className='inpField'
                                                title='Font Size'
                                                onChange={(e) => onInputChange(e, 'size')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <div className='row row-alt'>
                                        <div className='col-2'>
                                            <div
                                                className={`${styleState.fontWeight === "bold" ? "act" : ''} selectBt font-weight-bold `}
                                                onClick={() => onTextStyle('bold')}
                                                title="Text Bold"
                                            >T</div>
                                        </div>
                                        <div className='col-2'>
                                            <div
                                                className={`${styleState.fontStyle === "italic" ? "act" : ''} selectBt font-italic`}
                                                onClick={() => onTextStyle('italic')}
                                                title="Text Italic"
                                            >T</div>
                                        </div>
                                        <div className='col-2'>
                                            <div
                                                className={`${styleState.textTransform === "uppercase" ? "act" : ''} selectBt`}
                                                onClick={() => onTextStyle('uppercase')}
                                                title="Text Uppercase"

                                            >TT</div>
                                        </div>
                                        <div className='col-2'>
                                            <div
                                                className={`${styleState.textTransform === "lowercase" ? "act" : ''} selectBt`}
                                                onClick={() => onTextStyle('lowercase')}
                                                title="Text Lowercase"
                                            >tt</div>
                                        </div>
                                        <div className='col-2'>
                                            <div
                                                className={`${styleState.textDecoration === "underline" ? "act" : ''} selectBt text-decoration-underline `}
                                                onClick={() => onTextStyle('underline')}
                                                title="Text Underline"
                                            >T</div>
                                        </div>
                                        <div className='col-2'>
                                            <div
                                                className={`${styleState.textDecoration === "line-through" ? "act" : ''} selectBt text-decoration-line-through `}
                                                onClick={() => onTextStyle('strike')}
                                                title="Strike Through"
                                            >T</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <div className="row row-alt">
                                        <div className="col-2">
                                            <div
                                                className={`${styleState.textAlign === 'left' ? 'act' : ''}  selectBt `}
                                                onClick={() => onTextAlign('left')}
                                                title="Text Left"
                                            >
                                                <CgAlignLeft />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div
                                                className={`${styleState.textAlign === 'center' ? 'act' : ''}  selectBt `}
                                                onClick={() => onTextAlign('center')}
                                                title="Text Center"
                                            >
                                                <CgAlignCenter />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div
                                                onClick={() => onTextAlign('right')}
                                                className={`${styleState.textAlign === 'right' ? 'act' : ''}  selectBt `}
                                                title="Text Right"

                                            >
                                                <CgAlignRight />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div
                                                onClick={() => onTextAlign('justify')}
                                                className={`${styleState.textAlign === 'justify' ? 'act' : ''}  selectBt `}
                                                title="Text Justify"
                                            >
                                                <CgAlignMiddle />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                type="number"
                                                name="lineHeight"
                                                value={styleState.lineHeight}
                                                className='inpField'
                                                min={1}
                                                step="0.1"
                                                title='Line Height'
                                                onChange={(e) => onChangeLineHeight(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <ColorPattern
                                colorVal={styleState.color}
                                type="Color"
                                selectedSlideIndex={selectedSlideIndex}
                                selectedTextIndex={selectedTextIndex}
                            />

                            <ColorPattern
                                colorVal={styleState.backgroundColor}
                                type="Background"
                                selectedSlideIndex={selectedSlideIndex}
                                selectedTextIndex={selectedTextIndex}
                            />
                        </> : ""}

                    <div className="borderselect pt-3">
                        <h2 className="tabTitleN pb-3">Add Border</h2>
                        <div className="select-your-color-opt mt-0">
                            <input
                                type="number"
                                className="inpField"
                                min={0}
                                value={border.borderSize}
                                onChange={(e) => onSelectBorder(e.target.value)}
                            />
                        </div>
                        <ShadowBorder
                            type="border"
                            setBorder={setBorder}
                            border={border}
                            color={border.borderColor}
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                    </div>

                    <div className="borderselect pt-3">
                        <h2 className="tabTitleN pb-2">Add Shadow</h2>
                        <div className="select-your-color pt-0">
                            <ShadowBorder
                                type="shadow"
                                setBorder={setBorder}
                                border={border}
                                color={border.shadowColor}
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}

                            />
                        </div>
                    </div>
                    {allowType1.includes(layerType) ?

                        <div className="imageSelect forEmo pt-4">
                            <h2 className="tabTitleN pb-3">Filters</h2>
                            <Filters
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}
                            />
                        </div> : null}

                    <LayerLink
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                    />
                </div>
                : ""}
        </Tab.Pane >
    )
}

export default Customization