import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchSavedTempData, updateTemplate } from '../../../../actions/storyAction';
import customizeIcon from "../../../../images/customizeIcon.png"

const YourTemplates = ({ selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const myTemp = useSelector(state => state.extra.savedTemplate)
    const [state, setState] = useState({
        activeId: -1,
        searchKey: ""
    })

    const fetchSavedTemp = () => {
        dispatch(fetchSavedTempData())
    }
    const handleChange = (e) => {
        setState({
            ...state,
            searchKey: e.target.value
        })
    }

    const selectCustomeTemp = (curElem, index) => {
        setState({
            ...state,
            activeId: index
        })
        dispatch(updateTemplate(curElem, selectedSlideIndex, "custome"))
    }
    useEffect(() => {
        fetchSavedTemp()
    }, [])

    return (
        <Tab.Pane eventKey="yourTemp">
            <div className="imgOpt">
                <div className="imageSearch">
                    <input
                        type="text"
                        placeholder="Search Templates"
                        onChange={handleChange}
                    />
                </div>
                <div className="scrollDiv scrollDiv-3">
                    <div className="imageSelect">
                   
                        <ul>
                            {myTemp.length > 0 ?
                                myTemp.filter((item) => {
                                    if (state.searchKey !== "") {
                                        return item.name.toLowerCase().includes(state.searchKey.toLowerCase())
                                    }
                                    else {
                                        return item
                                    }
                                }).map((curElem, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className="cursor-pointer"
                                            onClick={() => selectCustomeTemp(curElem)}
                                            title={curElem.name}
                                        >
                                            <div className="imageSelect-single">
                                                <img src={customizeIcon} alt="" />
                                            </div>

                                        </li>

                                    )
                                })
                                : ""}
                        </ul>
                        {myTemp.length > 0 ? "" :
                            <div className='text-center'>
                                No saved template found
                            </div>
                        }

                    </div>
                </div>
            </div>
        </Tab.Pane>)
}

export default YourTemplates