import React, { useState } from 'react'
import { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { unselectLayers } from '../../actions/storyAction';

const BackgroundPreview = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (props.background.type === "video" || props.background.type === "image" || props.background.type === "gif") {
            setLoader(true)
        }
    }, [props.background.src]);

    const layersUnselect = () => {
        const isLayerSelected = props.selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (isLayerSelected !== -1) {
            dispatch(unselectLayers(props.selectedSlideIndex))
        }
    }
    return (
        props.background.type === "video" ?
            <div
                style={{ width: "100%", height: "100%", position: 'absolute', top: 0, }}
                onClick={layersUnselect}
                className="back-ground-hw"
            >
                {loader ?
                    <div className='preview-loader'>
                        <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#fbd90e", fontSize: "30px" }} />
                    </div>
                    : ""}
                <ReactPlayer
                    url={props.background.src}
                    playing={true}
                    loop={true}
                    onReady={() => setLoader(false)}
                    muted
                    style={{ visibility: `${loader ? 'hidden' : 'visible'}`, width: "100%!important", height: "100%!important" }}
                />
            </div> :
            props.background.type === "image" || props.background.type === "gif" ?
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        position: 'absolute',
                        top: 0,
                        textAlign: "center"
                    }}
                    onClick={layersUnselect}
                >
                    {loader ?
                        <div className='preview-loader'>
                            <i className="fa fa-spinner fa-spin" style={{ color: "#fbd90e", fontSize: "30px" }} />
                        </div>
                        : ""}
                    <img
                        className={`editor-background-img ${props.play ? "animate__animated animate__backInDown animate__delay-1s" : " "}`}
                        src={props.background.src}
                        alt=''
                        onLoad={() => setLoader(false)}
                        style={{ visibility: `${loader ? 'hidden' : 'visible'}`, }}
                    />
                </div> :
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        background: `${props.background.src}`
                    }}
                    onClick={layersUnselect}
                    className={`${props.play ? "animate__animated animate__backInUp animate__delay-1s" : " "}`}
                >
                </div >
    )
}

export default BackgroundPreview