import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "../../../Alert";
import MusicPlayer from "./MusicPlayer";
import { setAlert } from "../../../../actions/alert";
import { addVoiceOver, removeVoiceOver } from "../../../../actions/storyAction";

const Sonority = ({ selectedSlide, selectedSlideIndex }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const socialData = useSelector(state => state.social.sonority);

    const [loader, setLoader] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [musicData, setMusicData] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(false)
    const [currentUrl, setCurrentUrl] = useState('');
    const [currentMusic, setCurrentMusic] = useState("")
    const [state, setState] = useState({
        mergePicked: -1,
        musicPicked: -1,
        ttsPicked: -1
    })
    const connectSonority = () => {
        setShowDelete(true);
    }

    const onConfirm = () => {
        history.push('/integration')
    }

    const fetchSonorityMedia = () => {
        setLoader(true);

        axios({
            method: 'POST',
            url: 'https://regis12.amzitrage.com/fetch-all',
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': socialData[0].token }
        }).then(res => {
            if (res.data.status === true) {
                setMusicData(res.data.data);
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }



    const handleVoiceOver = (obj, index, type) => {
        if (type === "merge") {
            if (index === state.mergePicked) {
                setState({
                    ...state,
                    mergePicked: -1
                })
                dispatch(removeVoiceOver(selectedSlideIndex))
            } else {
                setState({
                    ...state,
                    mergePicked: index
                })
                dispatch(addVoiceOver(obj, selectedSlideIndex))
            }
        }
        else if (type === "music") {
            if (index === state.musicPicked) {
                setState({
                    ...state,
                    musicPicked: -1
                })
                dispatch(removeVoiceOver(selectedSlideIndex))
            } else {
                setState({
                    ...state,
                    musicPicked: index
                })
                dispatch(addVoiceOver(obj, selectedSlideIndex))
            }
        } else if (type === "tts") {
            if (index === state.ttsPicked) {
                setState({
                    ...state,
                    ttsPicked: -1
                })
                dispatch(removeVoiceOver(selectedSlideIndex))
            } else {
                setState({
                    ...state,
                    ttsPicked: index
                })
                dispatch(addVoiceOver(obj, selectedSlideIndex))
            }
        }

    }

    useEffect(() => {
        if (socialData.length > 0) {
            fetchSonorityMedia();
        }

        return () => {
            setMusicData(false);
        }
    }, [])

    return (
        <>
            <Alert />
            <div className='videoOpt'>
                <div className='scrollDiv scrollDiv-3'>
                    {
                        socialData.length > 0 ? <div className="alert alert-warning text-center" role="alert">
                            This music will apply to the current slide
                        </div>
                            : <div className="alert alert-warning text-center" role="alert">
                                You haven't connected your Sonority account yet. Click <a className="custom-anchor" onClick={() => connectSonority()}>Here</a> to connect and use those audios
                            </div>
                    }
                    {
                        loader ?
                            <div className="col-md-12 text-center">
                                <i className={`fa fa-spinner fa-spin`} style={{ color: '#fbd90e', fontSize: '20px' }} />
                            </div> : ''
                    }
                    {
                        loader ? '' : musicData !== false ? <h6>Audio Tracks</h6> : ''
                    }
                    <div className='imageSelect'>
                        <ul>
                            {

                                musicData !== false ?
                                    musicData.merge.map((item, index) => {
                                        let obj = {
                                            url: item.output_audio,
                                            title: item.name
                                        }
                                        return (
                                            <li
                                                key={index}
                                                onClick={() => handleVoiceOver(obj, index, "merge")}
                                            >
                                                <MusicPlayer
                                                    curElem={obj}
                                                    currentMusic={currentMusic}
                                                    setCurrentMusic={setCurrentMusic}
                                                    type={false}
                                                    check={state.mergePicked}
                                                    index={index}

                                                />
                                            </li>
                                        )
                                    })
                                    : ''
                            }
                        </ul>
                    </div>

                    {
                        loader ? '' : musicData !== false ? <h6>Music Tracks</h6> : ''
                    }
                    <div className='imageSelect'>
                        <ul>
                            {
                                musicData !== false ?
                                    musicData.music.map((item, index) => {
                                        let obj = {
                                            url: item.output_audio,
                                            title: item.title
                                        }
                                        return (
                                            <li
                                                key={index}
                                                onClick={() => handleVoiceOver(obj, index, "music")}
                                            >
                                                <MusicPlayer
                                                    curElem={obj}
                                                    currentMusic={currentMusic}
                                                    setCurrentMusic={setCurrentMusic}
                                                    type={false}
                                                    check={state.musicPicked}
                                                    index={index}
                                                />
                                            </li>
                                        )
                                    })
                                    : ''
                            }
                        </ul>
                    </div>

                    {
                        loader ? '' : musicData !== false ? <h6>Voice Tracks</h6> : ''
                    }
                    <div className='imageSelect'>
                        <ul>
                            {
                                musicData !== false ?
                                    musicData.tts.map((item, index) => {
                                        let obj = {
                                            url: item.output_audio,
                                            title: item.title
                                        }
                                        return (
                                            <li
                                                key={index}
                                                onClick={() => handleVoiceOver(obj, index, "tts")}
                                            >
                                                <MusicPlayer
                                                    curElem={obj}
                                                    currentMusic={currentMusic}
                                                    setCurrentMusic={setCurrentMusic}
                                                    type={false}
                                                    check={state.ttsPicked}
                                                    index={index}
                                                />
                                            </li>
                                        )
                                    })
                                    : ''
                            }
                        </ul>
                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText="Connect"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={() => onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                Please note that navigating from this page will discard all your customizations. Make sure you have saved the customizations.
            </SweetAlert>
        </>
    )
}

export default Sonority;