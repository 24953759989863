import React from 'react'
import webimg from '../../images/webimg.png'
import lessonImg from '../../images/lesson-img.png';
import { useSelector } from 'react-redux';
import { appName } from '../../global/global';
const fnc = () => {
    setTimeout(window.scrollTo(400, 1600), 5000);
};

function Webinar() {
    const rebrandData = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="lessonLink">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="lessonLink-left"><img src={lessonImg} /></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="lessonLink-right">
                                <h6><span>{rebrandData ? rebrandData.name : appName}</span> LESSON #1</h6>
                                <h2>Got questions? We’re here for you.</h2>
                                <p>Join us for a private training that will walk you through how to use {appName} AI to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards. </p>
                                <a className="demoLink" href="https://storyreel.io/training" target="_blanck"><i class="fa fa-play" aria-hidden="true"></i> Watch Training Here</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Webinar
