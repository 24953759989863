import React, { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Rnd } from 'react-rnd'
import { endAnimation, updateInlitialWH, updateLayerStatus } from '../../actions/storyAction'

const MediaLayer = (props) => {

  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const imageRef = useRef(null)
  const videoRef = useRef(null)

  const [style, setStyle] = useState({
    left: props.layer.position.left,
    top: props.layer.position.top,
    width: props.layer.size.width,
    height: props.layer.size.height,
    filter: props.layer.filter.name,
    border: props.layer.style.border,
    boxShadow: props.layer.style.boxShadow,
    animationDuration: props.layer.animation.duration,
    animationDelay: props.layer.animation.delay,
    objectFit: props.layer.style.objectFit,
    clipPath: props.layer.style.clipPath,
    borderRadius: props.layer.style.borderRadius,
    objectPosition: props.layer.style.objectPosition,
    opacity: props.layer.style.opacity,
    transform: props.layer.style.transform,
    position: "absolute",
  })
  
  useEffect(() => {
    const obj = {
      left: props.layer.position.left,
      top: props.layer.position.top,
      width: props.layer.size.width,
      height: props.layer.size.height,
      filter: props.layer.filter.name,
      border: props.layer.style.border,
      boxShadow: props.layer.style.boxShadow,
      animationDuration: props.layer.animation.duration,
      animationDelay: props.layer.animation.delay,
      objectFit: props.layer.style.objectFit,
      clipPath: props.layer.style.clipPath,
      borderRadius: props.layer.style.borderRadius,
      objectPosition: props.layer.style.objectPosition,
      opacity: props.layer.style.opacity,
      transform: props.layer.style.transform,
      position: "absolute",
    }
    setStyle(obj)
  }, [props.layer.size, props.layer.position, props.layer.style, props.layer.filter, props.layer.animation])

  const imageStyle = {
    display: "block",
    pointerEvents: "none",
    filter: style.filter,
    border: style.border,
    animationDelay: style.animationDelay,
    animationDirection: style.animationDuration,
    objectFit: style.objectFit,
    clipPath: style.clipPath,
    borderRadius: style.borderRadius,
    objectPosition: style.objectPosition,
    opacity: style.opacity,
    transform: style.transform,
  }


  const videoStyle = {
    filter: style.filter,
    border: style.border,
    borderRadius: style.borderRadius,
    animationDelay: style.animationDelay,
    animationDirection: style.animationDuration,
    clipPath: style.clipPath,
    maxInlineSize: "fit-content"
  }

  const handleOnLoadImage = () => {
    setLoader(false)
    dispatch(updateInlitialWH(imageRef.current.clientHeight, imageRef.current.clientWidth, props.selectedSlideIndex, props.index))
  }
  const handleOnLoadVideo = () => {
    setLoader(false)
    console.log(videoRef.current.videoHeight, videoRef.current.clientHeight, videoRef.current.clientHeight, videoRef.current.clientWidth)
    // dispatch(updateInlitialWH(videoRef.current.clientHeight, imageRef.current.clientWidth, props.selectedSlideIndex, props.index))
  }

  const handleClick = (layerIndex) => {
    dispatch(updateLayerStatus(layerIndex, props.selectedSlideIndex))
  }
  const handleAnimEnd = () => {
    dispatch(endAnimation(props.selectedSlideIndex, props.index))
  }

  useEffect(() => {
    if (props.layer.type === "video" || props.layer.type === "image" || props.layer.type === "gif") {
      setLoader(true)
    }
  }, [props.layer.src]);

  return (


    <Rnd
      scale={1}
      key={props.index}
      style={{ overflow: "hidden", display: "flex", justifyContent: "center", boxShadow: style.boxShadow, borderRadius: style.borderRadius }}
      enableResizing={props.layer.isSelected ? true : false}
      disableDragging={props.layer.isSelected ? false : true}
      enableUserSelectHack={true}
      onClick={() => handleClick(props.index)}
      default={{ x: 1, y: 1, height: style.height }}
      size={{ height: style.height, width: style.width }}
      position={{ x: style.left, y: style.top }}
      onDragStop={(e, d) => {
        setStyle({ ...style, left: d.x, top: d.y })
        props.handleRePosition(d.y, d.x, props.index)
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        console.log(ref.children)
        setStyle(
          {
            ...style,
            width: ref.style.width,
            height: ref.style.height,
            top: position.y,
            left: position.x
          }
        );

        props.handleResize(
          ref.style.width,
          ref.style.height,
          position.x,
          position.y,
          props.index,
          "image",
          ref.children[1].clientWidth,
          ref.children[1].clientHeight,
          ref.children[1].offsetLeft,
          ref.children[1].offsetTop
        )
      }}
      bounds=".vid-poster"
      className={props.layer.isSelected ? "myborder-layer" : ""}
    >
      {
        props.layer.type === "image" || props.layer.type === "gif" || props.layer.type === "emoji" ?
          <>
            {loader ?
              <div className='media-loader-2' style={{ position: "relative" }}>
                <i className="fa fa-spinner fa-spin" style={{ color: "#fbd90e", fontSize: "20px" }} />
              </div>
              : ""}
            <img
              ref={imageRef}
              style={imageStyle}
              src={props.layer.src}
              alt=''
              onLoad={() => handleOnLoadImage()}
              onAnimationEnd={() => handleAnimEnd()}
              className={` ${props.layer.animation.playAnimation ? props.layer.animation.name : ""} animate__animated ${loader ? 'invisible' : ""}`}
            />
          </>

          :

          props.layer.type === "video" ?
            <>
              {loader ?
                <div className='media-loader-2' style={{ position: "relative" }}>
                  <i className="fa fa-spinner fa-spin" style={{ color: "#fbd90e", fontSize: "20px" }} />
                </div>
                : ""}
              <video
                ref={videoRef}
                src={props.layer.src}
                autoPlay={true}
                muted={props.layer.mute}
                loop={true}
                onPlay={() => handleOnLoadVideo()}
                style={videoStyle}
                className={` ${props.layer.animation.playAnimation ? props.layer.animation.name : ""} animate__animated ${loader ? 'invisible' : ""}`}
                onAnimationEnd={() => handleAnimEnd()}
              />

            </>
            : null

      }
    </Rnd >
  )
}

export default MediaLayer