import React, { useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import modalCloseIcon from '../../../images/modal-close.png';
import Autocomplete from "react-google-autocomplete";
import { addCTA, addPollLayer, locationLayerAdd } from '../../../actions/storyAction';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { useLoadScript } from '@react-google-maps/api';

const AddOns = ({ selectedSlide, selectedSlideIndex }) => {
    const GOOGLE_MAPS_API_KEY = "AIzaSyCgWcuZtgkBF-7V9zZma4cDDi1kPt11mqw"
    const libraries = useMemo(() => ["places"], []);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });
    const dispatch = useDispatch()
    const [poll, setPoll] = useState({
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: ""
    })
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [show1, setShow1] = useState(false)
    const handleShow1 = () => setShow1(true)
    const handleClose1 = () => setShow1(false)

    const handleAddLocation = (place) => {
        dispatch(locationLayerAdd(place.formatted_address, selectedSlideIndex))
        handleClose()
    }

    const handleAddCTA = () => {
        dispatch(addCTA(selectedSlideIndex))
    }

    const addPolls = () => {
        handleShow1()
    }

    const handlePoll = (e) => {
        const { name, value } = e.target
        setPoll({
            ...poll,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (poll.option1 === "" && poll.option2 === "" && poll.option3 === "" && poll.option4 === "") {
            dispatch(setAlert("Please add at least 1 option for this poll", "danger"))
        }
        else {
            dispatch(addPollLayer(poll, selectedSlideIndex))
            handleClose1()
        }
    }
    return (
        <Tab.Pane eventKey="autoAnimate">
            <div className="animeOpt">
                <div className="secTitle">Add Ons</div>
                <div>
                    <div className="anime-select">
                        <ul>
                            <li onClick={addPolls}>
                                <div className="addOne-single">
                                    <img src="https://editor.makestories.io/static/media/location-add-on-icon.25c6d929.jpg" alt="" />
                                    <p>Polls</p>
                                </div>
                            </li>
                            <li onClick={() => handleShow()}>
                                <div className="addOne-single orange">
                                    <img src="https://editor.makestories.io/static/media/location-add-on-icon.25c6d929.jpg" alt="" />
                                    <p>Tag location</p>
                                </div>
                            </li>
                            <li onClick={() => handleAddCTA()}>
                                <div className="addOne-single red">
                                    <img src="https://editor.makestories.io/static/media/location-add-on-icon.25c6d929.jpg" alt="" />
                                    <p>CTA Buttons</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <Modal className="VideoModal small" show={show} onHide={handleClose} centered>
                    <Modal.Body>
                        <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                        <div className="inpField location-dropDown">
                            <label>Enter The Location name</label>

                            {isLoaded ? (
                                <Autocomplete
                                    apiKey={GOOGLE_MAPS_API_KEY}
                                    className="inpField-inp"
                                    onPlaceSelected={(place) => handleAddLocation(place)}
                                />
                            ) : null}
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className="VideoModal small ex" show={show1} onHide={handleClose1} centered>
                    <Modal.Body>
                        <div onClick={handleClose1} className="vidClose"><img src={modalCloseIcon} /></div>
                        <form className="inpField location-dropDown" onSubmit={handleSubmit}>
                            <label>Enter Your Question</label>
                            <textarea
                                className="form-control inpField-inp publish-discription mb-3"
                                required
                                name="question"
                                placeholder="Enter Question"
                                value={poll.question}
                                onChange={handlePoll}
                            />
                            <label>Enter Your Options</label>

                            <label className='m-0'>Option 1</label>
                            <input
                                className='inpField-inp sm mb-3'
                                value={poll.option1}
                                onChange={handlePoll}
                                name='option1'
                            />

                            <label className='m-0'>Option 2</label>
                            <input
                                className='inpField-inp sm mb-3'
                                onChange={handlePoll}
                                name='option2'
                                value={poll.option2}
                            />

                            <label className='m-0'>Option 3</label>
                            <input
                                className='inpField-inp sm mb-3'
                                onChange={handlePoll}
                                name='option3'
                                value={poll.option3}
                            />

                            <label className='m-0'>Option 4</label>
                            <input
                                className='inpField-inp sm mb-3'
                                onChange={handlePoll}
                                name='option4'
                                value={poll.option4}
                            />
                            <button
                                type='submit'
                                className='btn btn-primary'
                            >Add</button>
                        </form>
                    </Modal.Body>
                </Modal>

            </div>
        </Tab.Pane>
    )
}

export default AddOns