import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Image from './background/Image';
import Video from './background/Video';
import Gifs from './background/Gifs';
import Uploads from './background/Uploads';

const MediaLibrary = ({ selectedSlide, selectedSlideIndex }) => {
    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)

    return (
        <Tab.Pane eventKey="mediaLibrary">
            <Tab.Container defaultActiveKey="imAge">
                <Nav className="colNav">
                    <Nav.Item>
                        <Nav.Link eventKey="imAge">Images</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="viDeo">Videos</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="giFs">GIFs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="upLoads">Uploads</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Image
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                        type="mediaLayer"
                        selectedLayer={selectedLayer}
                        selectedLayerIndex={selectedLayerIndex}
                    />
                    <Video
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                        type="mediaLayer"
                        selectedLayer={selectedLayer}
                        selectedLayerIndex={selectedLayerIndex}
                    />
                    <Gifs
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                        type="mediaLayer"
                        selectedLayer={selectedLayer}
                        selectedLayerIndex={selectedLayerIndex}
                    />
                    <Uploads
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                        type="mediaLayer"
                        selectedLayer={selectedLayer}
                        selectedLayerIndex={selectedLayerIndex}
                    />
                </Tab.Content>
            </Tab.Container>

        </Tab.Pane>
    )
}

export default MediaLibrary