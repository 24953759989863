import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addFilter } from '../../../actions/storyAction';
import filtImg from "../../../images/filter.jpg";

const Filters = ({ selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const filterData = [
        {
            name: "Normal",
            data: "brightness(100%) contrast(100%) saturate(100%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: ""
        },
        {
            name: "Cali",
            data: "brightness(122%) contrast(54%) saturate(138%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-2"
        },
        {
            name: "Grayscale",
            data: "blur(0px) grayscale(100%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-3"
        },
        {
            name: "Drama",
            data: "brightness(90%) contrast(121%) saturate(134%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-4"
        },
        {
            name: "Edge",
            data: "brightness(110%) contrast(76%) saturate(126%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-5"
        },
        {
            name: "Epic",
            data: "brightness(106%) contrast(120%) saturate(86%) blur(0.25px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-6"
        },
        {
            name: "Festive",
            data: "brightness(110%) contrast(121%) saturate(124%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-7"
        },
        {
            name: "Nordic",
            data: "brightness(115%) contrast(84%) saturate(50%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-8"
        },
        {
            name: "Retro",
            data: "contrast(86%) saturate(82%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-9"
        },
        {
            name: "Rosie",
            data: "contrast(155%) saturate(72%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-10"
        },
        {
            name: "Selfie",
            data: "brightness(110%) contrast(88%) saturate(88%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-11"
        },
        {
            name: "Street",
            data: "brightness(93%) contrast(121%) saturate(5%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-12"
        },
        {
            name: "Summer",
            data: "brightness(110%) contrast(114%) saturate(118%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-13"
        },
        {
            name: "Blues",
            data: "brightness(163%) contrast(63%) saturate(74%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-14"
        },
        {
            name: "Whimsical",
            data: "brightness(143%) contrast(85%) saturate(47%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-15"
        },
        {
            name: "Light",
            data: "brightness(150%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-16"
        },
        {
            name: "Dark",
            data: "brightness(50%) blur(0px) grayscale(0%) sepia(0%) invert(0%) hue-rotate(0deg)",
            uiClassName: "img-filter-17"
        },
    ]

    const [state, setState] = useState({
        isSelectedIndex: -1
    })
    const handleFilter = (filter, index) => {
        if (selectedSlide.layers.length > 0) {
            const layerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
            if (layerIndex !== -1) {
                if (selectedSlide.layers[layerIndex].type !== "text") {
                    setState({
                        ...state,
                        isSelectedIndex: index === 0 ? -1 : index
                    })
                    dispatch(addFilter(filter, selectedSlideIndex, layerIndex))
                }
            }
        }
    }

    return (
        <ul>
            {filterData.length > 0 ?
                filterData.map((curElem, index) => {
                    return (
                        <li
                            key={index}
                            onClick={() => handleFilter(curElem.data, index)}
                            className="cursor-pointer"
                        >
                            <div className="imageFilt-single">
                                <div
                                    className="imageFilt-img"
                                    style={{ border: index === state.isSelectedIndex ? "2px solid #fbd90e" : "" }}
                                ><img className={curElem.uiClassName} src={filtImg} /></div>
                                <p>{curElem.name}</p>
                            </div>
                        </li>
                    )
                })
                : ""}
        </ul>
    )
}

export default Filters