import React, { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlinePlayCircle, AiOutlinePauseCircle } from "react-icons/ai";
import { SiAudiomack } from "react-icons/si";
import { GiCheckMark } from "react-icons/gi";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import { deleteUpload } from '../../../../actions/storyAction';

let audio = false
const MusicPlayer = (props) => {
    const dispatch = useDispatch()
    const [duration, setDuration] = useState(0)
    const [state, setState] = useState({
        play: false,
        showDelete: false,
        fileId: -1,
        button: "Delete"
    })
    // useEffect(() => {
    //     let au = document.createElement('audio');
    //     au.src = props.curElem.url;
    //     au.addEventListener('loadedmetadata', function () {
    //         setDuration(Number((au.duration).toFixed(1)));
    //     }, false);
    // }, [])
    props.curElem.duration = duration;

    const handlePlay = (e, music) => {
        e.stopPropagation();

        if (!audio || audio.src !== music) {
            // If there's no audio instance or the music is different
            if (audio) {
                audio.pause(); // Pause the current audio if it exists
                setState(prevState => ({
                    ...prevState,
                    play: false
                }));
            }

            audio = new Audio(music);
            audio.onended = () => {
                setState(prevState => ({
                    ...prevState,
                    play: false
                }));
            };

            audio.play();
            props.setCurrentMusic(music);
            setState(prevState => ({
                ...prevState,
                play: true,
            }));
        } else {
            // If the same audio is already playing, just pause it
            audio.pause();
            setState(prevState => ({
                ...prevState,
                play: false
            }));
        }
    };

    const handlePause = (e) => {
        e.stopPropagation();
        if (audio !== false) {
            audio.pause();
            setState({
                ...state,
                play: false
            })
        }
    }

    const handleDelete = (e) => {
        e.stopPropagation()
        setState({
            ...state,
            fileId: props.curElem.id,
            showDelete: true
        })
    }
    const onConfirm = () => {
        setState({
            ...state,
            button: "Deleting..."
        })
        const formData = new FormData()
        formData.append('id', state.fileId)
        dispatch(deleteUpload(formData, props.fetchFiles, state, setState, false))
    }
    const onCancel = () => {
        setState({
            ...state,
            fileId: -1,
            showDelete: false,
            delete: "Delete"
        })
    }

    useEffect(() => {
        return () => {
            if (audio) {
                audio.pause()
            }
            audio = false
        }
    }, [])
    useEffect(() => {
        if (props.curElem.url) {
            let au = document.createElement('audio');
            au.src = props.curElem.url;

            au.addEventListener('loadedmetadata', function () {
                if (au.duration !== Infinity && au.duration !== 0) {
                    setDuration(Number((au.duration).toFixed(1)));
                    au = null
                }
                else {
                    var getDuration = function (url, next) {
                        var _player = new Audio(url);
                        _player.load();
                        _player.currentTime = 24 * 60 * 60; // fake big time
                        _player.volume = 0;
                        _player.muted = false;
                        _player.play();

                        _player.addEventListener("durationchange", function (e) {
                            if (this.duration != Infinity) {
                                var duration = this.duration
                                _player.remove();
                                next(duration);
                            };
                        }, false);

                        // Wait for a user gesture (e.g., a click) before loading and playing the audio.
                        document.addEventListener("click", function () {
                            _player.load();
                            _player.currentTime = 24 * 60 * 60; // fake big time
                            _player.volume = 0;
                            _player.muted = false;
                            _player.play();
                        }, { once: true }); // Remove the event listener after it's triggered once.
                    };
                    if (au.src) {
                        getDuration(au.src, function (duration) {
                            setDuration(Number((duration).toFixed(1)));
                            au = null
                        });
                    }
                }
            }, false);
        }
    }, [props.curElem.url])

    return (
        <div className='audioSingle' style={{ border: props.check === props.index ? "2px solid #ffffff" : "" }} >
            <div className='audioSingle-top cursor-pointer'>
                {props.type === "upload" ?
                    <span className='audioSingle-del'>
                        <AiOutlineDelete
                            onClick={(e) => handleDelete(e)}
                        />
                    </span> : ""}

                <span className='audioSingle-dur'>{duration} sec</span>
                {props.check === props.index ? <GiCheckMark style={{ fontSize: "25px", color: "#ffffff" }} /> :
                    <SiAudiomack />}
            </div>
            <div
                className='audioSingle-bot'
            >
                {state.play && props.curElem.url === props.currentMusic ?
                    <AiOutlinePauseCircle
                        onClick={(e) => handlePause(e)}
                        style={{ cursor: 'pointer' }}
                    />
                    :
                    <AiOutlinePlayCircle
                        onClick={(e) => handlePlay(e, props.curElem.url)}
                        style={{ cursor: 'pointer' }}
                    />}
                <span>{props.curElem.title.slice(0, 15)}</span>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={state.button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={state.showDelete}
            >
                Do you want to delete this file
            </SweetAlert>


        </div>
    );
}
export default MusicPlayer;
