import React from 'react'
import Tab from 'react-bootstrap/Tab';
import { useDispatch } from 'react-redux';
import { textLayerAdd } from '../../../actions/storyAction';

const Text = ({ selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const addTextLayer = (size) => {
        // let isSelectedLayer = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        dispatch(textLayerAdd(selectedSlideIndex, size))
    }

    return (
        <Tab.Pane eventKey="teXt">
            <div className="imgOpt">
                <div className="secTitle">Text</div>
                <div className="heading scrollDiv">
                    <div className="heading-single cursor-pointer" onClick={() => addTextLayer(25)}>
                        <h1>Heading 1</h1>
                    </div>
                    <div className="heading-single cursor-pointer" onClick={() => addTextLayer(23.5)}>
                        <h2>Heading 2</h2>
                    </div>
                    <div className="heading-single cursor-pointer" onClick={() => addTextLayer(22)}>
                        <h3>Heading 3</h3>
                    </div>
                    <div className="heading-single cursor-pointer" onClick={() => addTextLayer(16)}>
                        <p>This is a paragraph (p)</p>
                    </div>
                    <div className="heading-single cursor-pointer" onClick={() => addTextLayer(14)}>
                        <span>Supporting Text (span)</span>
                    </div>
                </div>
            </div>
        </Tab.Pane>
    )
}

export default Text