import React, { useState } from 'react'
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import { AiOutlineCloudUpload, AiOutlineDelete, AiOutlinePlayCircle } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { setAlert } from '../../../../actions/alert';
import { muteVideoAudio, removeLogoWater, unMuteVideoAudio, updateDuration, updateLogo, updateLogoEnabel, updateLogoPos, uploadFileData } from '../../../../actions/storyAction';
import { commonAxios } from '../../../../global/CommonAxios';
import VolumeControl from './VolumeControl';
import { VscMute, VscUnmute } from "react-icons/vsc"
import download from '../../../../images/download.png'

const Settings = () => {
    const dispatch = useDispatch()

    const auth = useSelector(state => state.auth)
    const story = useSelector(state => state.story)

    const selectedSlide = story.slides.find(({ isSelected }) => isSelected === true)
    const selectedSlideIndex = story.slides.findIndex(({ isSelected }) => isSelected === true)
    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

    const [loadLogo, setLoadLogo] = useState(false);
    const [loadWatermark, setLoadWatermark] = useState(false);
    const [membership, setMembership] = useState([])
    const [logo, setLogo] = useState({
        enable: false,
        src: false,
        position: "top-left"
    })

    const [watermark, setWatermark] = useState({
        enable: false,
        src: false,
        position: "top-left"
    })

    const handleEnable = (value, type) => {
        dispatch(updateLogoEnabel(value, type))
    }
    const handlePos = (pos, type) => {
        dispatch(updateLogoPos(pos, type))
    }

    const onInputFile = (file, type) => {
        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
            if (file.size < 5000000) {
                let formData = new FormData();
                formData.append('file', file);
                formData.append('upload_type', type);
                formData.append('user_id', auth.user.id)

                if (type === "logo") {
                    setLoadLogo(true)
                }
                else {
                    setLoadWatermark(true)
                }
                commonAxios("upload-file", formData, dispatch, auth.token)
                    .then((res) => {
                        if (res.status) {
                            dispatch(updateLogo(res.data.path, type))
                        } else {
                            dispatch(setAlert(res.msg, 'danger'))
                        }
                        setLoadLogo(false)
                        setLoadWatermark(false)
                    }).catch(() => {
                        setLoadLogo(false);
                        setLoadWatermark(false);
                    })
            } else {
                swal("Oops!", "Max allowed size 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }

    const handleDuration = (val) => {
        dispatch(updateDuration(val, selectedSlideIndex))
    }

    const handleMuteVideoLayer = (selectedLayer) => {

        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (selectedLayer.mute === false) {
            dispatch(muteVideoAudio(selectedSlideIndex, selectedLayerIndex))
        }
    }

    function hasAudio(video) {
        return video.mozHasAudio ||
            Boolean(video.webkitAudioDecodedByteCount) ||
            Boolean(video.audioTracks && video.audioTracks.length);
    }
    const handleUnMuteVideoLayer = (selectedLayer) => {
        let video = document.querySelector('#checkAudio');
        if (hasAudio(video)) {
            const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
            if (selectedLayer.mute) {
                dispatch(unMuteVideoAudio(selectedSlideIndex, selectedLayerIndex))
            }
        } else {
            dispatch(setAlert("This video does not have an audio", "danger"))
        }

    }

    useEffect(() => {
        if (story.logo) {
            if (story.logo.logoId !== "") {
                let logoLayer = story.slides[0].layers.find(({ id }) => id === story.logo.logoId)
                if (logoLayer) {
                    setLogo({
                        ...logo,
                        enable: story.logo.enable,
                        src: logoLayer.src,
                        position: story.logo.pos
                    })
                }
            } else {
                setLogo({
                    ...logo,
                    enable: story.logo.enable,
                    src: false,
                    position: story.logo.pos
                })
            }
        }
    }, [story.logo])

    useEffect(() => {
        if (story.watermark) {
            if (story.watermark.watermarkId !== "") {
                let watermarkLayer = story.slides[0].layers.find(({ id }) => id === story.watermark.watermarkId)
                if (watermarkLayer) {
                    setWatermark({
                        ...watermark,
                        enable: story.watermark.enable,
                        src: watermarkLayer.src,
                        position: story.watermark.pos
                    })
                }
            } else {
                setWatermark({
                    ...watermark,
                    enable: story.watermark.enable,
                    src: false,
                    position: story.watermark.pos
                })
            }


        }
    }, [story.watermark])

    useEffect(() => {
        if (auth.user) {
            setMembership(auth.user.membership.split("__"))
        }
    }, [auth.user])
    return (
        <Tab.Pane eventKey="seTTings">
            <div className="animeOpt">
                <div className="secTitle">Settings</div>
                <div className='scrollDiv scrollDiv-3'>
                    <div className='optBox'>
                        <div className='optBox-top'>
                            <p className='innerHead'>Add logo</p>
                            <label class="switch">
                                <input
                                    type="checkbox"
                                    checked={logo.enable}
                                    onChange={(e) => handleEnable(e.target.checked, "logo")}
                                />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        {logo.enable ?
                            <div className='optBox-cont'>
                                <div className="compactUpload mb-3">
                                    <div>
                                        <div><AiOutlineCloudUpload /></div>
                                        <p>Upload Your Logo (Max Size 5MB)</p>
                                    </div>
                                    <input
                                        type="file"
                                        accept='image/*'
                                        onChange={(e) => onInputFile(e.target.files[0], "logo")}
                                    />
                                </div>
                                <div className='row row-alt'>
                                    <div className='col-5'>
                                        <div className='logoupImg' style={{ border: "2px solid white" }}>
                                            {loadLogo ? <i className="fa fa-spinner fa-spin loader-center" /> :
                                                <img className="normImg" src={logo.src ? logo.src : download} alt="" />}

                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <select
                                            className='inpField'
                                            id=""
                                            onChange={(e) => handlePos(e.target.value, "logo")}
                                        >
                                            <option value={'top-left'}>Top Left</option>
                                            <option value={'top-center'}>Top Center</option>
                                            <option value={'top-right'}>Top Right</option>
                                            <option value={'bottom-left'}>Bottom Left</option>
                                            <option value={'bottom-center'}>Bottom Center</option>
                                            <option value={'bottom-right'}>Bottom Right</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            : ""}
                        <hr />
                    </div>
                    {membership.includes("professional") || membership.includes("professionallite") || membership.includes("bundle") ?
                        <div className='optBox mt-5'>
                            <div className='optBox-top'>
                                <p className='innerHead'>Add Watermark</p>
                                <label class="switch">
                                    <input
                                        type="checkbox"
                                        checked={watermark.enable}
                                        onChange={(e) => handleEnable(e.target.checked, "watermark")}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            {watermark.enable ?
                                <div className='optBox-cont'>
                                    <div className="compactUpload mb-3">
                                        <div>
                                            <div><AiOutlineCloudUpload /></div>
                                            <p>Upload Your Watermark (Max Size 5MB)</p>
                                        </div>
                                        <input
                                            type="file"
                                            accept='image/*'
                                            onChange={(e) => onInputFile(e.target.files[0], "watermark")}
                                        />
                                    </div>

                                    <div className='row row-alt'>
                                        <div className='col-5'>
                                            <div className='logoupImg' style={{ border: "2px solid white" }}> {loadWatermark ? <i className="fa fa-spinner fa-spin loader-center" /> :
                                                <img className="normImg" src={watermark.src ? watermark.src : download} alt="" />}</div>
                                        </div>
                                        <div className='col-7'>
                                            <select
                                                className='inpField'
                                                id=""
                                                onChange={(e) => handlePos(e.target.value, "watermark")}
                                            >
                                                <option value={'top-left'}>Top Left</option>
                                                <option value={'top-center'}>Top Center</option>
                                                <option value={'top-right'}>Top Right</option>
                                                <option value={'bottom-left'}>Bottom Left</option>
                                                <option value={'bottom-center'}>Bottom Center</option>
                                                <option value={'bottom-right'}>Bottom Right</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                            <hr />
                        </div> : ""}

                    <div className='optBox mt-5'>
                        <div className='optBox-top'>
                            <p className='innerHead'>Duration (Sec)</p>
                        </div>
                        <div className='optBox-cont'>
                            <div className='row row-alt'>
                                <div className='col-12'>
                                    <input
                                        type="number"
                                        className='inpField text-left'
                                        min={1}
                                        value={selectedSlide.duration}
                                        onChange={(e) => handleDuration(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedLayer?.type === "video" ?
                        <div className='optBox mt-5'>
                            <div className='optBox-top'>
                                <p className='innerHead'>Mute/Unmute Video</p>
                            </div>
                            <div className='optBox-cont'>
                                <div className='row row-alt'>
                                    <div className='col-12'>
                                        {
                                            selectedLayer.mute ?
                                                <VscMute
                                                    size={25}
                                                    onClick={() => handleUnMuteVideoLayer(selectedLayer)}
                                                    className="cursor-pointer"
                                                /> :
                                                <VscUnmute
                                                    size={25}
                                                    onClick={() => handleMuteVideoLayer(selectedLayer)}
                                                    className="cursor-pointer"

                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ""}
                    <VolumeControl />
                </div>
                {selectedLayer ?
                    <video
                        src={selectedLayer.src ? selectedLayer.src : ""}
                        className="no-showvideotag"
                        id="checkAudio"
                    /> : ""
                }
            </div>
        </Tab.Pane>
    )
}

export default Settings