import React from "react";
import imac from "../../images/IMAC.png"
import mac from "../../images/comp-1.png"
import mac2 from "../../images/comp-2.png"
import mac3 from "../../images/comp-3.png"
import mac4 from "../../images/comp-4.png"
import { useSelector } from "react-redux";


const DashboardAbout = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return(
        <>
            <section className="featureSec">
                    <div className="container">
                        <div className="featureSec-wrap">
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           {/* <h6>GET STARTED WITHOUT A PLAN</h6> */}
                                           <h2><span>Create Stunning Stories</span> From 100s Of Professionally-Designed Templates</h2>
                                           <p>Get attention-grabbing story-style videos that inspire your viewers to swipe up. Save hours editing and get stunning stories in minutes.</p>
                                           {/* <div className="textL">See how you can use StoryReel to <span>create your first course</span></div> */}
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img src={mac} /></div>
                                   </div>
                               </div>
                            </div>
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           {/* <h6>Polish your videos effortlessly</h6> */}
                                           <h2><span>Access 1000s</span> Of Images, Fonts, Music And Videos For Your Content</h2>
                                           <p>Effortlessly create a short video that conveys and elevates your brand with a rich library of visual elements, music graphics, and fonts to choose from.</p>
                                           {/* <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div> */}
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img src={mac2} /></div>
                                   </div>
                               </div>
                            </div>
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           {/* <h6>TAKE A BACKSEAT</h6> */}
                                           <h2><span>Fully-Customize your Story-Style</span> Videos In A Few Clicks</h2>
                                           <p>Make your audience remember your videos easily by branding it with your own media content, logo, and watermark that boost brand retention.</p>
                                           {/* <div className="textL">Find out how the <span>Team Members Access</span> feature works</div> */}
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img src={mac3} /></div>
                                   </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    )
}

export default DashboardAbout;