import React, { useEffect, useState } from "react";
import ManageClient from "./ManageClient";
import ManageTeam from "./ManageTeam";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import Alert from "../Alert";
import { setAlert } from "../../actions/alert";

const AddAccount = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [resellerData, setResellerData] = useState([]);

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'team_member',
        email: '',
        name: '',
        password: '',
        user_id: auth.user.id
    });

    const [passwordError, setPasswordError] = useState('');

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({ ...userDetails, [name]: value });

        if (name === "password") {
            validatePassword(value);
        }
    };

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*]/.test(password);

        if (password.length < minLength) {
            setPasswordError(`Password must be at least ${minLength} characters long.`);
        } else if (!hasUpperCase || !hasLowerCase || !hasNumbers || !hasSpecialChars) {
            setPasswordError('Password must contain uppercase, lowercase, numbers, and special characters.');
        } else {
            setPasswordError('');
        }
    };

    const fetchClientTeam = () => {
        axios.post(`${baseURL}fetch-clients`, {}, {
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setTeamData(res.data.data.team_members);
                setClientData(res.data.data.clients);
                setResellerData(res.data.data.resellers);
            }
        }).catch(error => {
            console.error("Error fetching clients:", error);
        });
    };

    const addClientTeam = () => {
        setLoader(true);

        const apiUrl = userDetails.type === "reseller" ? `${baseURL}add-reseller` : `${baseURL}create-client`;

        axios.post(apiUrl, userDetails, {
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchClientTeam();
                setUserDetails({
                    ...userDetails,
                    email: '',
                    name: '',
                    password: ''
                });
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
        }).catch(error => {
            dispatch(setAlert('An error occurred while creating the account.', 'danger'));
        }).finally(() => {
            setLoader(false);
        });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (passwordError) {
            dispatch(setAlert(passwordError, 'danger'));
            return;
        }
        addClientTeam();
    };

    useEffect(() => {
        fetchClientTeam();
    }, []);

    return (
        <>
            <Alert />

            <div className="profileBlock-wrap">
                <h2 className="infoTitle">Create Account</h2>
                <div className="profileBlock mt-0">
                    <form onSubmit={onFormSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0 mb-4 mb-md-0">
                                    <label className="form-text text-muted">Account Type</label>
                                    <select className="form-control" name="type" onChange={onInputChange}>
                                        <option value="team_member">Team Member</option>
                                        <option value="client">Client Account</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt mt-0">
                                    <label className="form-text text-muted">Account Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Account Name"
                                        required
                                        name="name"
                                        value={userDetails.name}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        required
                                        name="email"
                                        value={userDetails.email}
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group form-group-alt">
                                    <label className="form-text text-muted">Account Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="********"
                                        required
                                        name="password"
                                        value={userDetails.password}
                                        onChange={onInputChange}
                                    />
                                    {passwordError && <div className="text-danger">{passwordError}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="fbtn btn-block demoLink" disabled={loader || passwordError}>
                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} {loader ? "Creating" : "Create"}
                            </button>
                        </div>
                    </form>
                </div>

                <ManageClient users={clientData} fetchClientTeam={fetchClientTeam} type="Client" />
                <ManageTeam users={teamData} fetchClientTeam={fetchClientTeam} type="Team Member" />
            </div>
        </>
    );
};

export default AddAccount;
