import React from 'react'
import Tab from 'react-bootstrap/Tab';
import parse from "html-react-parser";
import { emoji } from '../../../../fonts/IconsFile';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addLayerMedia, textLayerAdd, textLayerUpdate } from '../../../../actions/storyAction';
import { setAlert } from '../../../../actions/alert';
import { commonAxios } from '../../../../global/CommonAxios';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect } from 'react';


const Emojis = ({ selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [emojiArr, setEmojiArr] = useState([])
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        selectedEmoji: -1,
        searchKey: "",
        page: 1,
    })
    const handleEmoji = (url, index) => {
        setState({
            ...state,
            selectedEmoji: index
        })
        let objData = {
            url: url,
            mediaType: "image"
        }
        dispatch(addLayerMedia(objData, selectedSlideIndex))
    }
    const handleChange = (e) => {
        setEmojiArr([])
        setState({
            ...state,
            searchKey: e.target.value
        })
    }

    const fetchImage = (e, page = 1) => {
        if (e) {
            e.preventDefault()
            setEmojiArr([])
        }
        setLoader(true);
        let data = {
            keyword: state.searchKey,
            type: "emoji",
            page_number: page
        }

        commonAxios("load-library-images", data, dispatch, auth.token)
            .then((res) => {
                if (res.status) {
                    let arr = [...emojiArr];
                    setEmojiArr(arr.concat(res.data));
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setLoader(false);
                setState({ ...state, page: page });
            }).catch((err) => {
                setLoader(false);
                console.log(err)
            })
    }

    useEffect(() => {
        fetchImage()
    }, [])
    return (
        <Tab.Pane eventKey="elemTab-1">
            <div className="imgOpt">
                <div className="imageSearch">
                    <form onSubmit={fetchImage}>
                        <input
                            type="text"
                            placeholder="Search Elements"
                            onChange={(e) => handleChange(e)}
                        />

                    </form>

                </div>

                <div className="imageSelect forEmo">
                    <ul className='scrollDiv scrollDiv-3' id='scrollableEmoji'>
                        <InfiniteScroll
                            dataLength={emojiArr.length}
                            next={() => {
                                fetchImage(false, state.page + 1)
                            }}
                            hasMore={true}
                            scrollableTarget={`scrollableEmoji`}
                        >
                            {emojiArr.length > 0 ?
                                emojiArr.map((curElem, index) => {
                                    return (
                                        <li
                                            key={index}
                                            onClick={() => handleEmoji(curElem.local_url, index)}
                                            style={{ cursor: "pointer", padding: "5px" }}
                                            className="mt-2"
                                        >
                                            <img
                                                className="singleEmo"
                                                src={curElem.local_url}
                                                alt={curElem.name}
                                                style={{ border: state.selectedEmoji === index ? "1px solid #fbd90e" : "" }}
                                            />
                                        </li>
                                    )
                                }) : ""
                            }
                        </InfiniteScroll>
                    </ul>
                    {
                        loader ?
                            <div className="col-md-12">
                                <h4 className='text-center'>
                                    <i className="fa fa-spinner fa-spin" style={{ color: "#fbd90e" }} />
                                </h4>
                            </div>
                            : ''
                    }
                </div>
            </div>
        </Tab.Pane>
    )
}

export default Emojis