import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../Alert";
import { fetchStoriesAll } from "../../actions/storyAction";
import Rendering from "./Rendering";


const StoriesCard = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const [stories, setStories] = useState([])
  const [loader, setLoader] = useState(false)

  const getStories = () => {
    setLoader(true)
    dispatch(fetchStoriesAll(auth.token, setStories, setLoader, props.showRecord))
  }

  useEffect(() => {
    getStories()
  }, [])
  return (
    <>
      <Alert />
      <div className="row my-1 my-md-3">
        {stories.length > 0 ?
          stories.filter((item) => {
            if (props.searchKey && !props.showRecord) {
              return item.name.toLowerCase().includes(props.searchKey.toLowerCase())
            }
            else {
              return item
            }
          }).map((curElem, index) => {
            return (
              <div className="col-xl-3 col-sm-6 col-12" key={index}>
                <Rendering
                  curElem={curElem}
                  getStories={getStories}
                />
              </div>

            )
          })
          : !loader ? <div className="col-md-12">
            <div className="text-center text-muted ">
              <h4>You do not have any story created in your account</h4>
            </div>
          </div> : ''}

        {
          loader ?
            <div className="col-md-12 text-center" >
              <i className="fa fa-spinner fa-spin" style={{ color: "#fbd90e", fontSize: "34px" }} />
            </div> : ''
        }
      </div>


    </>
  )
}

export default StoriesCard;