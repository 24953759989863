import React, {useEffect, useState} from "react";
import Alert from "../Alert";
import {useDispatch, useSelector} from "react-redux";
import ManageClient from "./ManageClient";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";

const AddResellerAccount = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'reseller',
        email: '',
        name: '',
        password: ''
    });

    const onInputChange = (e) => {
        setUserDetails({...userDetails, [e.target.name]: e.target.value});
    }

    const fetchReseller = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-reseller-accounts`,
            data: {},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                setClientData(res.data.accounts)
            }
        }).catch(error => {

        })
    }

    const addReseller = () => {
        axios({
            method: "POST",
            url: `${baseURL}reseller`,
            data: userDetails,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchReseller()
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        addReseller();
    }

    useEffect(()=>{
        fetchReseller();
    },[])

    return (
        <>
            <Alert/>

            <div className="profileBlock-wrap">
               <h2 className="infoTitle">Create Reseller Account</h2>
               <div className="profileBlock mt-0">

               <form className="" method="post" onSubmit={(e) => onFormSubmit(e)}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group form-group-alt mt-0">
                            <label>Account Name</label>
                            <input type="text" className="form-control"
                                   placeholder="Enter Account Name" required name="name"
                                   onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group form-group-alt">
                            <label>Account Email</label>
                            <input type="email" className="form-control" placeholder="Enter Email"
                                   required name="email"
                                   onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group form-group-alt">
                            <label>Account Password</label>
                            <input type="password" className="form-control"
                                   placeholder="********" required name="password"
                                   onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                <button type="submit" className="fbtn btn-block demoLink" disabled={loader}> {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Create</button>
                </div>

            </form>

               </div>
            </div>

            <ManageClient users={clientData} fetchClientTeam={fetchReseller} type="Reseller"/>
        </>
    )
}

export default AddResellerAccount;