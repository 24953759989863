import { BsSquareFill, BsFillCircleFill, BsCircle, BsTriangleFill, BsTriangle, BsFillPentagonFill, BsPentagon, BsHexagonFill, BsHexagon, } from "react-icons/bs"
import { IoSquareSharp, IoSquareOutline } from "react-icons/io5"
import { GiSquare } from "react-icons/gi"


export const shapesArr = [
    {
        shape: <IoSquareSharp />,
        type: "reactIcon",
        name: "IoSquareSharp"
    },
    {
        shape: <BsSquareFill />,
        name: "BsSquareFill",
        type: "reactIcon"
    },
    {
        shape: <BsFillCircleFill />,
        name: "BsFillCircleFill",
        type: "reactIcon"
    },
    {
        shape: <BsTriangleFill />,
        name: "BsTriangleFill",
        type: "reactIcon"
    },
    {
        shape: <BsFillPentagonFill />,
        name: "BsFillPentagonFill",
        type: "reactIcon"
    },
    {
        shape: <BsHexagonFill />,
        name: "BsHexagonFill",
        type: "reactIcon"
    },
    {
        shape: <GiSquare />,
        name: "GiSquare",
        type: "reactIcon"
    },
    {
        shape: <IoSquareOutline />,
        name: "IoSquareOutline",
        type: "reactIcon"
    },
    {
        shape: <BsCircle />,
        name: "BsCircle",
        type: "reactIcon"
    },
    {
        shape: <BsTriangle />,
        name: "BsTriangle",
        type: "reactIcon"
    },
    {
        shape: <BsPentagon />,
        name: "BsPentagon",
        type: "reactIcon"
    },
    {
        shape: <BsHexagon />,
        name: "BsHexagon",
        type: "reactIcon"
    }
]