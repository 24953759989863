import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Gradient from './Gradient';
import Image from './Image';
import Video from './Video';
import Uploads from './Uploads';
import Gifs from './Gifs';
import Patterns from './Patterns';
import Color from './Color';
import { useSelector } from 'react-redux';

const Background = ({ selectedSlide, selectedSlideIndex }) => {



  return (
    <Tab.Pane eventKey="backGround">
      <Tab.Container defaultActiveKey="coLor">
        <Nav className="colNav colNavDivide">
          <Nav.Item>
            <Nav.Link eventKey="coLor">Color</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="graDient">Gradient</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="imAge">Image</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="viDeo">Video</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="giFs">GIFs</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="patTerns">Patterns</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="upLoads">Uploads</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Color
            selectedSlide={selectedSlide}
            selectedSlideIndex={selectedSlideIndex}
          />
          <Gradient
            selectedSlide={selectedSlide}
            selectedSlideIndex={selectedSlideIndex}
          />
          <Image
            selectedSlide={selectedSlide}
            selectedSlideIndex={selectedSlideIndex}
            type="background"
          />
          <Video
            selectedSlide={selectedSlide}
            selectedSlideIndex={selectedSlideIndex}
            type="background"
          />
          <Uploads
            selectedSlide={selectedSlide}
            selectedSlideIndex={selectedSlideIndex}
            type="background"
          />
          <Gifs
            selectedSlide={selectedSlide}
            selectedSlideIndex={selectedSlideIndex}
            type="background"
          />
          <Patterns
            selectedSlide={selectedSlide}
            selectedSlideIndex={selectedSlideIndex}
          />
        </Tab.Content>
      </Tab.Container>
    </Tab.Pane>
  )
}

export default Background   