import React, { useState, useEffect } from 'react';
import videoThumb from '../../images/fav-new2.png';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import { onDeleteStory } from '../../actions/storyAction';
import { commonAxios } from '../../global/CommonAxios';
import PublishModal from './PublishModal';
import { ImEmbed2 } from "react-icons/im";
import Embed from './Embed';


const Rendering = ({ curElem, getStories }) => {
    let rendCheck = false
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [state, setState] = useState(1)
    const [showDelete, setShowDelete] = useState(false);
    const [button, setButton] = useState('Delete');
    const [showModel, setShowModel] = useState(false)
    const [showModel1, setShowModel1] = useState(false)
    const [checkData, setCheckData] = useState({
        renderProgress: 0,
        renderStatus: ""
    })
    const [slideId, setSlideId] = useState("")

    const [selectedId, setSelectedId] = useState({
        id: -1,
        storyName: ""
    });

    const handleDelete = (id, name) => {
        setSelectedId({
            ...selectedId,
            id: id,
            storyName: name
        })
        setShowDelete(true)
    }

    const onConfirm = () => {
        let data = {
            story_id: selectedId.id,
        }
        setButton("Deleting...")
        dispatch(onDeleteStory(data, auth.token, getStories, setButton, setShowDelete))
    }

    const checkRendering = () => {
        let data = {
            storyId: curElem.id
        }
        commonAxios("story-status", data, dispatch, auth.token)
            .then((res) => {
                if (res.status) {
                    if (res.data[0].status === "4") {
                        setCheckData({
                            ...checkData,
                            renderProgress: res.data[0].progress,
                            renderStatus: res.data[0].render_status
                        })
                    }
                    else if (res.data[0].status === "2") {
                        clearInterval(rendCheck)
                        rendCheck = false
                        getStories()
                    }
                    else if (res.data[0].status === "3") {
                        clearInterval(rendCheck)
                        rendCheck = false
                    }
                } else {
                    clearInterval(rendCheck)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    const handlePublish = () => {
        setShowModel(true)
    }
    const handleEmbed = (id) => {
        setSlideId(id)
        setShowModel1(true)
    }

    useEffect(() => {
        setState(parseInt(curElem.status))
    }, [curElem.status])

    useEffect(() => {
        if (parseInt(state) === 4) {
            rendCheck = setInterval(() => {
                checkRendering()
            }, 5000)
        }
        return () => {
            clearInterval(rendCheck)
            rendCheck = false
        }
    }, [state])

    return (
        <>
            <div className="videoProject-single">
                <div className="videoProject-single-top ">
                    {parseInt(state) === 4 ?
                        <div className='proStat'>
                            <div className='proStatIn'>
                                <div className='proStat-top'>
                                    <div className='proStatSingle m-2' style={{ display: "inline-block", }} >
                                        <p style={{ fontSize: '17px', color: "#000", fontWeight: "bold" }}>Your Progress</p>

                                        <h5 style={{ fontSize: '14px', color: "#000 " }}>{checkData.renderStatus}</h5>
                                    </div>
                                    <div className='proStatSingle m-2' style={{ display: "inline-block", }}>
                                        <p style={{ fontSize: '17px', color: "#000", fontWeight: "bold" }}>Completed</p>
                                        <span style={{ fontSize: '14px', color: "#000", fontWeight: '600' }}>{checkData.renderProgress}%</span>
                                    </div>
                                </div>
                                <div className="progress-bar-status">
                                    <div className="progress " style={{ backgroundColor: ' rgb(0 0 0 / 47%)' }}>
                                        <div className=" progress-bar-render progress-bar-striped bg-danger progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100%" style={{ width: `${parseInt(checkData.renderProgress)}%` }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        parseInt(state) === 2 ?
                            <img src={curElem.thumbnail} alt='' style={{ width: "100%", height: "100%" }} />
                            :
                            parseInt(state) === 3 ?
                                <div class="status transcribing w-100 d-flex align-items-center justify-content-center"
                                    style={{ background: "linear-gradient(to right, rgb(219 13 13), rgb(139 25 14))", height: "100%" }}>
                                    <img class="img-fluid status-img" alt="" src={videoThumb} />
                                    <p class="md-txt text-white font-italic mb-0"
                                        style={{ fontWeight: 500, position: "absolute", top: "60%" }}>Rendering Failed</p>
                                </div>
                                :
                                <div class="status transcribing w-100 d-flex align-items-center justify-content-center"
                                    style={{ background: "linear-gradient(to right, rgb(255, 171, 59), rgb(190, 99, 19))", height: "100%" }}>
                                    <img class="img-fluid status-img" alt="" src={videoThumb} />
                                    <p class="md-txt text-white font-italic mb-0"
                                        style={{ fontWeight: 500, position: "absolute", top: "60%" }}>Awaiting Customization</p>
                                </div>
                    }

                    <div className="vidHover">
                        <div><p>{curElem.created}</p></div>
                        <div className="vidiconAll">
                            {auth.user.is_client_account !== "1" ?
                                <div className="vidHover-single">
                                    <Link to={`/create-story?id=${curElem.id}`}>
                                        <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                        <span>Edit</span>
                                    </Link>
                                </div> : ""}
                            {curElem.status === "2" ?
                                <>
                                    <div className="vidHover-single">
                                        <a href={`${curElem.output_video}`} >
                                            <span className="vidHover-icon"><i className="fa fa-download" aria-hidden="true"></i></span>
                                            <span>Download</span>
                                        </a>
                                    </div>
                                    {auth.user.is_client_account !== "1" ?
                                        <div className="vidHover-single cursor-pointer">
                                            <a onClick={handlePublish} >
                                                <span className="vidHover-icon"><i className="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                                <span>Publish</span>
                                            </a>
                                        </div> : ""}
                                </>
                                : ''}
                            {auth.user.is_client_account !== "1" ?
                                <div className="vidHover-single cursor-pointer" onClick={() => handleDelete(curElem.id, curElem.name)}>
                                    <a>
                                        <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                        <span>Delete</span>
                                    </a>
                                </div> : ''}

                            {auth.user.is_client_account !== "1" ?
                                <div className="vidHover-single cursor-pointer" >
                                    <a onClick={() => handleEmbed(curElem.id)}>
                                        <span className="vidHover-icon " ><ImEmbed2 size={22} style={{ marginTop: "-5px" }} /></span>
                                        <span>Embed</span>
                                    </a>
                                </div> : ''}
                        </div>
                    </div>
                </div>
                <div className="videoProject-single-bottom">
                    <span>{curElem.name}</span>
                </div>
            </div>

            <PublishModal
                showModel={showModel}
                setShowModel={setShowModel}
                curElem={curElem}
            />
            <Embed
                showModel1={showModel1}
                setShowModel1={setShowModel1}
                slideId={slideId}
            />

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this story "{selectedId.storyName}"
            </SweetAlert>
        </>
    );
}

export default Rendering;
