import React, { useState } from 'react';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useDispatch } from 'react-redux';
import { addBgMusic, getAudio, removeBgMusic } from '../../../../actions/storyAction';
import MusicPlayer from './MusicPlayer';

const BgMusic = ({ selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [currentMusic, setCurrentMusic] = useState("")
    const [state, setState] = useState({
        loader: false,
        pickedMusic: -1,
        page: 1,
        duration: 0
    })

    const fetchAudio = (page = 1) => {
        setState({
            ...state,
            loader: true,
            page: page
        });
        let obj = {
            search_type: 'music',
            page_number: page,
            keyword: ''
        }
        dispatch(getAudio(obj, data, setData, state, setState))
    }

    const handleMusic = (musicObj, musicIndex) => {
        if (musicIndex === state.pickedMusic) {
            setState({
                ...state,
                pickedMusic: -1
            })
            dispatch(removeBgMusic())
        } else {
            setState({
                ...state,
                pickedMusic: musicIndex
            })
            dispatch(addBgMusic(musicObj))
        }

    }

    useEffect(() => {
        fetchAudio();
        return () => {
            setData([]);
        }
    }, [])

    return (
        <div className='videoOpt'>
            <div className='scrollDiv scrollDiv-4' id='bgMusicScroll'>
                <div className='imageSelect' >
                    <ul>
                        <InfiniteScroll
                            dataLength={data.length}
                            hasMore={true}
                            onScroll={() => fetchAudio(state.page + 1)}
                            scrollableTarget="bgMusicScroll"
                        >
                            {data.length > 0 ?
                                data.map((curElem, index) => {
                                    return (
                                        <li
                                            key={index}
                                            onClick={() => handleMusic(curElem, index)}
                                        >
                                            <>
                                                <MusicPlayer
                                                    curElem={curElem}
                                                    currentMusic={currentMusic}
                                                    setCurrentMusic={setCurrentMusic}
                                                    type={false}
                                                    check={state.pickedMusic}
                                                    index={index}
                                                />
                                            </>
                                        </li>

                                    )
                                })
                                : ""}

                        </InfiniteScroll>
                    </ul>
                    {
                        state.loader ?
                            <div className='text-center' style={{ fontSize: "20px", color: "#fbd90e" }}>
                                <i className="fa fa-spinner fa-spin mx-1" />
                            </div> : ""
                    }
                </div>
            </div>
        </div>
    );
}

export default BgMusic;
