import React,{useState} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import UpgradesMenu from "./UpgradesMenu";
import UpgradesContent from "./UpgradesContent";
import TitleBar from "../TitleBar";

const Upgrades = () => {
    const[show ,setShow] = useState(false)

    return(
        <>
            <TitleBar title="Upgrades"/>
            <Navbar />

            <section className="siteWrap">
                <div className="tabCont white">
                    <div className="row">
                        <div className="col-lg-4 tabLeft">
                            <div className="tabCont-left">
                               <UpgradesMenu setShow={setShow}/>
                            </div>
                        </div>

                        
                    <UpgradesContent show={show} />
                            
                    </div>
                </div>
            </section> 

            <Footer/>
        </>
    )
}

export default Upgrades;