import React, { useState, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import { searchPatternData, updateBgMedia } from '../../../../actions/storyAction';
import { useSelector, useDispatch } from 'react-redux';

const Patterns = ({selectedSlideIndex}) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [pattern, setPattern] = useState([])
    const [state, setState] = useState({
        selectedPattern: -1,
        searchKey: ""
    })
    const fetchPattern = () => {
        dispatch(searchPatternData(auth.token, setPattern))
    }

    const handlePattern = (url, index) => {
        setState({
            ...state,
            selectedPattern: index
        })
        let objData = {
            url: url,
            mediaType: "image"
        }
        dispatch(updateBgMedia(objData, selectedSlideIndex))
    }

    useEffect(() => {
        fetchPattern()
    }, [])
    return (
        <Tab.Pane eventKey="patTerns">
            <div className="imgOpt">
                {/* <div className="imageSearch">
                    <input type="text" placeholder="Search Patterns" />
                </div> */}
                <div className="scrollDiv scrollDiv-3">
                    <div className="imageSelect forPattern">
                        <ul>
                            {pattern.length > 0 ?
                                pattern.map((curElem, index) => {
                                    return (
                                        <li
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handlePattern(curElem.logo, index)}
                                        >
                                            <div
                                                className="imageSelect-single forGif"
                                                style={{ border: state.selectedPattern === index ? "1px solid #fbd90e" : "" }}
                                            >
                                                <img src={curElem.logo} alt="" />
                                            </div>
                                        </li>
                                    )
                                }) : ""}
                        </ul>
                    </div>
                </div>
            </div>
        </Tab.Pane>
    )
}

export default Patterns