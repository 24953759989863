import React from 'react'
import Tab from 'react-bootstrap/Tab';
import { SketchPicker } from 'react-color'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateBgColor } from '../../../../actions/storyAction';


const Color = ({ selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch();

    const colorPallets = [
        '#dee2e6',
        '#55d4a8',
        '#03a9f4',
        '#00bcd4',
        '#aa00ff',
        '#ff1744',
        '#b388ff',
        '#e040fb',
        '#ce93d8',
        '#3d5afe'
    ]
    const [color, setColor] = useState({
        enable: false,
        r: 0,
        g: 0,
        b: 0
    });

    const onInputColor = (e) => {
        let colorVal = e.hex
        setColor({
            ...color,
            r: e.rgb.r,
            g: e.rgb.g,
            b: e.rgb.b
        })
        dispatch(updateBgColor(colorVal, selectedSlideIndex));
    }

    const handelBgColor = (colorVal) => {
        dispatch(updateBgColor(colorVal, selectedSlideIndex));
    }



    return (
        <Tab.Pane eventKey="coLor">
            <div className="coloropt">
                <div className="colorSelect">
                    <ul>
                        {colorPallets.map((curElem, index) => {
                            return (
                                <li key={index}>
                                    <div
                                        className="coloropt-single"
                                        style={{ background: curElem }}
                                        onClick={() => handelBgColor(curElem)}>
                                    </div>
                                </li>
                            )
                        })}

                    </ul>
                </div>

                <div className="select-your-color">
                    <p className="innerHead">Select your color</p>
                    <div className="select-your-color-opt">
                        <input
                            type="text"
                            value={selectedSlide.background.type === "color" ? selectedSlide.background.src.includes("linear") ? "" : selectedSlide.background.src : ""}
                            readOnly
                        />
                        <div className="colorPick">
                            <div
                                className='color-picker'
                                style={{
                                    background: selectedSlide.background.src
                                }}
                                onClick={() => setColor({
                                    ...color,
                                    enable: true
                                })}
                            />
                            {
                                color.enable ?
                                    <>
                                        <div className="swatch-cover" onClick={() => setColor({
                                            ...color,
                                            enable: false
                                        })} />
                                        <SketchPicker
                                            className='input-picker-css'
                                            color={selectedSlide.background.src}
                                            onChange={(e) => onInputColor(e)}
                                        />
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane>
    )
}

export default Color