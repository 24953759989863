import React, {useState} from "react";
import {useSelector} from "react-redux";
import queryString from "query-string";
import {Link} from "react-router-dom";

const TrainingContents = ({location}) => {

    const supportData = useSelector(state => state.support);
    const articleId = queryString.parse(location.search).id;

    const [articles, setArticles] = useState(supportData.articles);

    return(
        <>
            <div className="col-lg-8 tabRight" style={{paddingTop: "0"}}>
                <div className="tabepaneIn forTraining">

                    <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                            {
                                articles.length > 0 ?
                                    articles.map((article,index)=>{
                                        return(
                                            article.id === articleId ?
                                                article.items.map((item,index)=>{
                                                    return(
                                                        <>
                                                        <div className="tabepaneIn-main" key={index}>
                                                            <div className="tabepaneIn-cont">

                                                            <h3 key={index} className="">
                                                                <Link to={`/support-article?id=${articleId}&art_id=${item.id}`}>
                                                                    {item.title}
                                                                </Link>
                                                            </h3>
                                                            <div key={index} className="mt-4">
                                                                <img src={item.feature_url} className="img-fluid" alt="" />
                                                            </div>

                                                            </div>
                                                        </div>
                                                        </>
                                                    )
                                                })
                                            : ''
                                        )
                                    })
                                : ''
                            }

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingContents;