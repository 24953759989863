import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import YourTemplates from './YourTemplates';
import InBuiltTemplates from './InBuiltTemplates';

const Template = ({ selectedSlide, selectedSlideIndex }) => {
    return (
        <Tab.Pane eventKey="temPlates">
            <div className="imgOpt">
                <div>
                    <Tab.Container defaultActiveKey="inTemp">
                        <Nav className="colNav">
                            <Nav.Item>
                                <Nav.Link eventKey="inTemp">In-built Templates</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="yourTemp">Your Templates</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <InBuiltTemplates
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}
                            />
                            <YourTemplates
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}
                            />
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </Tab.Pane>
    )
}

export default Template