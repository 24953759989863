import produce from "immer"
const initialState = {
    publishDetails: {
        storyTitle: "",
        storyLanguage: "",
        publisherName: "",
        logo: "",
        favIcon: ""
    },
    seo: {
        title: "",
        seoKeyword: "",
        description: "",
        imageUrl: ""
    },
    analyticsAndAds: {
        analytics: ""
    },
    domain: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "UPDATE_PUBLISH_STORY_TITLE":
            return produce(state, (draft) => {
                draft.publishDetails.storyTitle = action.payload
            })
        case "UPDATE_PUBLISH_STORY_LANGUAGE":
            return produce(state, (draft) => {
                draft.publishDetails.storyLanguage = action.payload
            })
        case "UPDATE_PUBLISH_STORY_NAME":
            return produce(state, (draft) => {
                draft.publishDetails.publisherName = action.payload
            })

        case "UPDATE_LOGO_IMAGE":
            return produce(state, (draft) => {
                draft.publishDetails.logo = action.payload
            })
        case "UPDATE_FAV_IMAGE":
            return produce(state, (draft) => {
                draft.publishDetails.favIcon = action.payload
            })

        case "UPDATE_SEO_TITLE":
            return produce(state, (draft) => {
                draft.seo.title = action.payload
            })
        case "UPDATE_SEO_KEY":
            return produce(state, (draft) => {
                draft.seo.seoKeyword = action.payload
            })
        case "UPDATE_SEO_DESC":
            return produce(state, (draft) => {
                draft.seo.description = action.payload
            })
        case "UPDATE_SEO_IMGURL":
            return produce(state, (draft) => {
                draft.seo.imageUrl = action.payload
            })
        case "UPDATE_ANALYTICS_TEXT":
            return produce(state, (draft) => {
                draft.analyticsAndAds.analytics = action.payload
            })
        case "UPDATE_DOMAIN":
            return produce(state, (draft) => {
                draft.domain = action.payload
            })


        default:
            return state
    }
}