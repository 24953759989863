import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import hexRgb from "hex-rgb";
import { useDispatch } from 'react-redux';
import { addBorder, addShadow } from '../../../actions/storyAction';

const ShadowBorder = (props) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        picker: false,
        color: "#ffffff",
        r: 0,
        g: 0,
        b: 0
    })
    const handlePicker = () => {
        setState({
            ...state,
            picker: true
        })
    }

    const handleChangeColor = (e) => {
        const val = hexRgb(e.hex)
        setState({
            ...state,
            color: e.hex,
            r: val.red,
            g: val.green,
            b: val.blue
        })

        if (props.type === "border") {
            props.setBorder({
                ...props.border,
                borderColor: e.hex
            })
            updateBorderColor(e.hex)
        } else {
            props.setBorder({
                ...props.border,
                shadowColor: e.hex
            })
            updateShadowColor(e.hex)
        }
    }

    const updateBorderColor = (color) => {
        let layerIndex = props.selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (layerIndex !== -1) {
            let borderData = `${props.selectedSlide.layers[layerIndex].style.border !== "" ? props.selectedSlide.layers[layerIndex].style.border[0] : 1}px solid ${color}`
            if (props.selectedSlide.layers.length > 0) {
                dispatch(addBorder(borderData, props.selectedSlideIndex, layerIndex))
            }
        }
    }
    // box - shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
    const updateShadowColor = (color) => {
        let layerIndex = props.selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (layerIndex !== -1) {
            let shedowrData = `${color} 10px 10px 11px -3px`
            if (props.selectedSlide.layers.length > 0) {
                dispatch(addShadow(shedowrData, props.selectedSlideIndex, layerIndex))
            }
        }
    }

    return (
        <div className="select-your-color pt-0">
            <div className="select-your-color-opt " style={{ position: "relative" }}>
                <input
                    type="text"
                    value={state.color}
                />
                <div
                    className="colorPick cursor-pointer"
                    onClick={() => handlePicker()}
                    style={{ background: props.color }}
                ></div>
                {state.picker ?
                    <>
                        <div
                            onClick={() => setState({
                                ...state,
                                picker: false
                            })}
                            className="swatch-cover "
                        />
                        <SketchPicker
                            className='set-picker-pos input-picker-css'
                            color={props.color}
                            onChange={(e) => handleChangeColor(e)}
                        />
                    </>
                    : ''}
            </div>
        </div>
    )
}

export default ShadowBorder