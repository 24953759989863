import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import Animation from './Animation';
import Customization from './Customization';
import { useDispatch } from 'react-redux';

const EditorRight = ({ storyData }) => {
    const dispatch = useDispatch()
    const selectedSlide = storyData.slides.find(({ isSelected }) => isSelected === true)
    const selectedSlideIndex = storyData.slides.findIndex(({ isSelected }) => isSelected === true)
    return (
        <div className="editRight">
            <Tab.Container id="left-tabs-example" defaultActiveKey="designTab">
                <Nav className="colNav colNav-fill">
                    <Nav.Item>
                        <Nav.Link eventKey="designTab">Customization</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="animationTab">Animation</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                              <Nav.Link eventKey="settingsTab">Settings</Nav.Link>
                            </Nav.Item> */}
                </Nav>
                <Tab.Content>

                    <Customization
                        slides={storyData.slides}
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                    />
                    <Animation
                        slides={storyData.slides}
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                    />
                    {/* <Tab.Pane eventKey="settingsTab">
                               <div className="select-your-color">
                                    <p className="innerHead">Settings</p>
                                </div>
                            </Tab.Pane> */}
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default EditorRight