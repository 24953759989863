import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../actions/authAction";

const ProfilePassword = () => {
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    });
    const [error, setError] = useState('');

    const onInputChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
        setError('');
    };

    const validatePasswords = () => {
        const { password, new_password, confirm_password } = userDetails;

        if (new_password.length < 8) {
            return 'New password must be at least 8 characters long.';
        }

        if (new_password !== confirm_password) {
            return 'New password and confirmation do not match.';
        }

        if (new_password === password) {
            return 'New password must be different from the current password.';
        }

        if (!/[A-Z]/.test(new_password)) {
            return 'New password must contain at least one uppercase letter.';
        }

        if (!/[a-z]/.test(new_password)) {
            return 'New password must contain at least one lowercase letter.';
        }

        if (!/[0-9]/.test(new_password)) {
            return 'New password must contain at least one number.';
        }

        if (!/[!@#$%^&*(),.?":{}|<>]/.test(new_password)) {
            return 'New password must contain at least one special character.';
        }

        return '';
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        const validationError = validatePasswords();
        if (validationError) {
            setError(validationError);
            setLoader(false);
            return;
        }

        dispatch(updatePassword(userDetails, setLoader));
    };

    return (
        <>
            <div className="profileBlock">
                <h2 className="text-center">Reset Password</h2>
                <form method="post" onSubmit={onFormSubmit}>
                    <div className="form-group form-group-alt">
                        <label className="form-text text-muted">Current Password</label>
                        <input
                            type="password"
                            className="form-control"
                            placeholder="**********"
                            name="password"
                            required
                            onChange={onInputChange}
                        />
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">New Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="**********"
                                    name="new_password"
                                    required
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group form-group-alt">
                                <label>Confirm Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="**********"
                                    name="confirm_password"
                                    required
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                    </div>

                    {error && <div className="alert alert-danger my-3 text-center py-2">{error}</div>}

                    <div className="alert alert-info my-3 text-center py-2" role="alert">
                        Updating your password will log you out for re-login.
                    </div>

                    <button
                        type="submit"
                        className="fbtn btn-block demoLink mt-0"
                        disabled={loader}
                    >
                        {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update
                    </button>
                </form>
            </div>
        </>
    );
};

export default ProfilePassword;
