import React, { useState } from 'react';
import { useEffect } from 'react';
import { BiPlayCircle, BiPauseCircle } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux';
import { updateAiVolume, updateBgVolume } from '../../../../actions/storyAction';

let bgAudio = false, aiAudio = false
const VolumeControl = () => {
    const dispatch = useDispatch()
    const story = useSelector(state => state.story)
    const selectedSlide = story.slides.find(({ isSelected }) => isSelected === true)
    const selectedSlideIndex = story.slides.findIndex(({ isSelected }) => isSelected === true)
    const [play, setPlay] = useState(false)

    const handlePlay = () => {
        if (bgAudio === false && story.bg_audio.enable) {
            bgAudio = new Audio(story.bg_audio.source)
            bgAudio.volume = story.bg_audio.volume / 100
            bgAudio.play()
        }
        if (aiAudio === false && selectedSlide.audio.enable) {
            aiAudio = new Audio(selectedSlide.audio.src)
            aiAudio.volume = selectedSlide.audio.volume / 100
            aiAudio.play()
        }
        if (bgAudio || aiAudio) {
            setPlay(true)
        }
    }
    const handlePause = () => {
        if (bgAudio !== false) {
            bgAudio.pause()
            bgAudio = false
        }
        if (aiAudio !== false) {
            aiAudio.pause()
            aiAudio = false
        }
        if (bgAudio === false || aiAudio === false) {
            setPlay(false)
        }
    }

    const handleAiVolume = (e) => {
        handlePause()
        dispatch(updateAiVolume(e.target.value, selectedSlideIndex))
    }

    const handleBgVolume = (e) => {
        handlePause()
        dispatch(updateBgVolume(e.target.value))
    }

    useEffect(() => {
        return () => {
            handlePause()
        }
    }, [])


    return (
        <div className='optBox mt-5'>
            <hr />
            <div className='optBox-top'>
                <p className='innerHead'>Volume Control</p>

                <div>
                    {selectedSlide.audio.enable || story.bg_audio.enable ?
                        play ?
                            <BiPauseCircle
                                onClick={handlePause}
                                size={20}
                                color={"red"}
                                className="cursor-pointer"
                            />
                            :
                            <BiPlayCircle
                                onClick={handlePlay}
                                size={20}
                                className="cursor-pointer"
                            />
                        :
                        ""}
                </div>
            </div>
            {selectedSlide.audio.enable || story.bg_audio.enable ? "" :
                <div class="alert alert-info" role="alert">
                    Once you apply music or ai-voice you will see volume controls respectively
                </div>}
            {selectedSlide.audio.enable ?
                <div>
                    <div className='optBox-top mb-0'>
                        <p className='innerHead'>Voiceover</p>
                        <div>{selectedSlide.audio.volume}</div>
                    </div>
                    <input
                        type="range"
                        className="sliderN cursor-pointer"
                        id="myRange"
                        min={1}
                        value={selectedSlide.audio.volume}
                        onChange={handleAiVolume}
                    />
                </div> : ""}
            {story.bg_audio.enable ?
                <div>
                    <div className='optBox-top mb-0 mt-3'>
                        <p className='innerHead'>Music</p>
                        <div>{story.bg_audio.volume}</div>
                    </div>
                    <input
                        type="range"
                        className="sliderN cursor-pointer"
                        id="myRange2"
                        min={1}
                        value={story.bg_audio.volume}
                        onChange={handleBgVolume}
                    />
                </div> : ""}
        </div>
    );
}

export default VolumeControl;
