import React from 'react'
import Nav from 'react-bootstrap/Nav';
import { FiClipboard, FiMusic, FiSettings } from "react-icons/fi";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { RiText } from "react-icons/ri";
import { IoImageOutline } from "react-icons/io5";
import { BsBox, BsBoxSeam } from "react-icons/bs";

const Sidebar = () => {
  return (
    <div className="iconBar">
      <Nav variant="" className="flex-column iconNav">
        <Nav.Item>
          <Nav.Link eventKey="backGround"><FiClipboard /></Nav.Link>
          <span className="navTooltip">Background</span>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="temPlates"><MdOutlineSpaceDashboard /></Nav.Link>
          <span className="navTooltip">Templates</span>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="teXt"><RiText /></Nav.Link>
          <span className="navTooltip">Text</span>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="mediaLibrary"><IoImageOutline /></Nav.Link>
          <span className="navTooltip">Media Library</span>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="audio"><FiMusic /></Nav.Link>
          <span className="navTooltip">Audio</span>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="eleMents"><BsBox /></Nav.Link>
          <span className="navTooltip">Elements</span>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="autoAnimate"><BsBoxSeam /></Nav.Link>
          <span className="navTooltip">Add Ons</span>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="seTTings"><FiSettings /></Nav.Link>
          <span className="navTooltip">Settings</span>
        </Nav.Item>
      </Nav>
    </div>
  )
}

export default Sidebar