import React from "react";

const SocialIcons = (props) => {

    return (
        <>
            <li className={`${props.status ? '' : 'disable'} cursor-pointer ${props.selectIcon} mx-2`}  >
                <div className="img-wrraper">
                    <img src={props.icon} alt={props.name} width="56px" className="mt-3" />
                </div>
                <p className="mt-1 m-0">{props.name}</p>
            </li>
        </>
    )
}

export default SocialIcons;