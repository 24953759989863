import React from 'react'
import Tab from 'react-bootstrap/Tab';
import { SketchPicker } from 'react-color'
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateBgColor } from '../../../../actions/storyAction';

const Gradient = ({ selectedSlide, selectedSlideIndex }) => {

    const dispatch = useDispatch();
    const [color, setColor] = useState({
        enable1: false,
        g1: "#ffffff",
        enable2: false,
        g2: "#ffffff",
        enable3: false,
        g3: "#ffffff"
    });


    const onInputColor = (e, place) => {
        let colorVal = e.hex
        let gradient
        if (place === "g1") {
            gradient = `linear-gradient(${e.hex}, ${color.g2}, ${color.g3})`
            setColor({
                ...color,
                g1: colorVal
            })

        } else if (place === "g2") {
            gradient = `linear-gradient(${color.g1}, ${e.hex}, ${color.g3})`
            setColor({
                ...color,
                g2: colorVal
            })
        } else {
            gradient = `linear-gradient(${color.g1}, ${color.g2}, ${e.hex})`
            setColor({
                ...color,
                g3: colorVal
            })
        }

        dispatch(updateBgColor(gradient, selectedSlideIndex));
    }

    useEffect(() => {
        if (selectedSlide.background.type === "color") {
            let val = selectedSlide.background.src
            val = val.split(" ")
            // setColor({
            //     ...color,
            //     g1: val[0].,
            //     g2: ,
            //     g3: 
            // })
        }
    }, [selectedSlide])



    return (
        <Tab.Pane eventKey="graDient">
            <div className="coloropt">
                <div className="select-your-color">
                    <p className="innerHead">Gradient Color - 1</p>
                    <div className="select-your-color-opt">
                        <input
                            type="text"
                            value={color.g1}
                            readOnly
                        />
                        <div className="colorPick">
                            <div
                                className='color-picker cursor-pointer'
                                style={{
                                    background: color.g1
                                }}
                                onClick={() => setColor({
                                    ...color,
                                    enable1: true
                                })}
                            />
                            {
                                color.enable1 ?
                                    <>
                                        <div className="swatch-cover" onClick={() => setColor({
                                            ...color,
                                            enable1: false
                                        })} />
                                        <SketchPicker
                                            className='input-picker-css'
                                            color={color.g1}
                                            onChange={(e) => onInputColor(e, "g1")}
                                        />
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>

                <div className="select-your-color">
                    <p className="innerHead">Gradient Color - 2</p>
                    <div className="select-your-color-opt">
                        <input
                            type="text"
                            value={color.g2}
                            readOnly
                        />
                        <div className="colorPick">
                            <div
                                className='color-picker cursor-pointer'
                                style={{
                                    background: color.g2
                                }}
                                onClick={() => setColor({
                                    ...color,
                                    enable2: true
                                })}
                            />
                            {
                                color.enable2 ?
                                    <>
                                        <div className="swatch-cover" onClick={() => setColor({
                                            ...color,
                                            enable2: false
                                        })} />
                                        <SketchPicker
                                            className='input-picker-css'
                                            color={color.g2}
                                            onChange={(e) => onInputColor(e, "g2")}
                                        />
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>

                <div className="select-your-color">
                    <p className="innerHead">Gradient Color - 3</p>
                    <div className="select-your-color-opt">
                        <input
                            type="text"
                            value={color.g3}
                            readOnly
                        />
                        <div className="colorPick">
                            <div
                                className='color-picker cursor-pointer'
                                style={{
                                    background: color.g3
                                }}
                                onClick={() => setColor({
                                    ...color,
                                    enable3: true
                                })}
                            />
                            {
                                color.enable3 ?
                                    <>
                                        <div className="swatch-cover" onClick={() => setColor({
                                            ...color,
                                            enable3: false
                                        })} />
                                        <SketchPicker
                                            className='input-picker-css'
                                            color={color.g3}
                                            onChange={(e) => onInputColor(e, "g3")}
                                        />
                                    </>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Tab.Pane>
    )
}

export default Gradient