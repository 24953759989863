import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updatePoll } from '../../../actions/storyAction'

const Poll = ({ selectedSlide, selectedSlideIndex, selectedText }) => {
    const dispatch = useDispatch()
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const handleChangePoll = (e) => {
        const { name, value } = e.target
        dispatch(updatePoll(name, value, selectedSlideIndex, selectedLayerIndex))
    }

    return (
        < div className="select-your-color pt-0">
            <div className="select-head d-flex justify-content-between">
                <p className="innerHead">Poll</p>
            </div>
            <div className="select-your-color-opt mb-2">
                <textarea
                    className="inpField"
                    name='question'
                    value={selectedText.src ? selectedText.src.question : ""}
                    onChange={handleChangePoll}
                />
            </div>
            <div className="select-head d-flex justify-content-between">
                <p className="innerHead">Options</p>
            </div>
            <div className="select-your-color-opt full">

                <div className='opt-single'>
                <label>1.</label>
                <input
                    className="inpField"
                    type="input"
                    name='option1'
                    onChange={handleChangePoll}
                    value={selectedText.src ? selectedText.src.option1 : ""}
                />
                </div>

                <div className='opt-single'>
                <label>2.</label>
                <input
                    className="inpField"
                    type="input"
                    name='option2'
                    value={selectedText.src ? selectedText.src.option2 : ""}
                    onChange={handleChangePoll}
                />
                </div>

                <div className='opt-single'>
                <label>3.</label>
                <input
                    className="inpField"
                    type="input"
                    name='option3'
                    value={selectedText.src ? selectedText.src.option3 : ""}
                    onChange={handleChangePoll}
                />
                </div>

                <div className='opt-single'>
                <label>4.</label>
                <input
                    className="inpField"
                    type="input"
                    name='option4'
                    value={selectedText.src ? selectedText.src.option4 : ""}
                    onChange={handleChangePoll}
                />
                </div>
            </div>
        </div>
    )
}

export default Poll