import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { endAnimation, updateLayerStatus } from '../../actions/storyAction'
import { Rnd } from "react-rnd";
import { shapesArr } from '../../fonts/IconsFile'


const TextLayer = (props) => {
    const dispatch = useDispatch()
    let dataVal = props.layer.src
    if (props.layer.type === "shape") {
        let tempData = shapesArr.find(({ name }) => name === props.layer.src)
        dataVal = tempData.shape
    }

    const [style, setStyle] = useState({
        color: props.layer.style.color,
        width: props.layer.size.width,
        height: props.layer.size.height,
        fontSize: props.layer.style.fontSize,
        fontWeight: props.layer.style.fontWeight,
        fontFamily: props.layer.style.fontFamily,
        lineHeight: props.layer.style.lineHeight,
        textTransform: props.layer.style.textTransform,
        textDecoration: props.layer.style.textDecoration,
        background: props.layer.style.background ? props.layer.style.background : props.layer.style.backgroundColor,
        textAlign: props.layer.style.textAlign,
        opacity: props.layer.style.opacity,
        transform: props.layer.style.transform,
        fontStyle: props.layer.style.fontStyle,
        boxShadow: props.layer.style.boxShadow,
        top: props.layer.position.top,
        left: props.layer.position.left,
        border: props.layer.position.border,
        animationDuration: props.layer.animation.duration,
        animationDelay: props.layer.animation.delay,
        position: "absolute",
        display: props.layer.style.display,
        alignItems: props.layer.style.alignItems,
        justifyContent: props.layer.style.justifyContent,
        borderRadius: props.layer.style.borderRadius,
        textShadow: props.layer.style.textShadow,
        writingMode: props.layer.style.writingMode,
        borderWidth: props.layer.style.borderWidth,
        borderStyle: props.layer.style.borderStyle,
        borderImage: props.layer.style.borderImage,
        borderImageSlice: props.layer.style.borderImageSlice,
        borderTopRightRadius: props.layer.style.borderTopRightRadius,
        borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
        borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
        borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
        letterSpacing: props.layer.style.letterSpacing,
        padding: props.layer.style.padding
    })

    useEffect(() => {
        setStyle({
            ...style,
            color: props.layer.style.color,
            width: props.layer.size.width,
            height: props.layer.size.height,
            fontSize: props.layer.style.fontSize,
            fontWeight: props.layer.style.fontWeight,
            fontFamily: props.layer.style.fontFamily,
            fontStyle: props.layer.style.fontStyle,
            lineHeight: props.layer.style.lineHeight,
            textTransform: props.layer.style.textTransform,
            textDecoration: props.layer.style.textDecoration,
            background: props.layer.style.background ? props.layer.style.background : props.layer.style.backgroundColor,
            textAlign: props.layer.style.textAlign,
            opacity: props.layer.style.opacity,
            top: props.layer.position.top,
            left: props.layer.position.left,
            clipPath: props.layer.style.clipPath,
            border: props.layer.style.border,
            boxShadow: props.layer.style.boxShadow,
            transform: props.layer.style.transform,
            animationDuration: props.layer.animation.duration,
            animationDelay: props.layer.animation.delay,
            position: "absolute",
            display: props.layer.style.display,
            alignItems: props.layer.style.alignItems,
            justifyContent: props.layer.style.justifyContent,
            borderRadius: props.layer.style.borderRadius,
            textShadow: props.layer.style.textShadow,
            writingMode: props.layer.style.writingMode,
            borderWidth: props.layer.style.borderWidth,
            borderStyle: props.layer.style.borderStyle,
            borderImage: props.layer.style.borderImage,
            borderImageSlice: props.layer.style.borderImageSlice,
            borderTopRightRadius: props.layer.style.borderTopRightRadius,
            borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
            borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
            borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
            letterSpacing: props.layer.style.letterSpacing,
            padding: props.layer.style.padding
        })
    }, [props.layer.style, props.layer.position, props.layer.size, props.layer.animation])

    let localState = {
        color: style.color,
        fontFamily: style.fontFamily,
        background: style.background,
        fontSize: parseInt(style.fontSize),
        textAlign: style.textAlign,
        fontWeight: style.fontWeight,
        fontStyle: style.fontStyle,
        textTransform: style.textTransform,
        textDecoration: style.textDecoration,
        clipPath: style.clipPath,
        lineHeight: style.lineHeight,
        opacity: style.opacity,
        border: style.border,
        transform: style.transform,
        animationDuration: style.animationDuration,
        animationDelay: style.animationDelay,
        position: "absolute",
        overflow: "hidden",
        display: style.display,
        alignItems: style.alignItems,
        justifyContent: style.justifyContent,
        borderRadius: style.borderRadius,
        textShadow: style.textShadow,
        writingMode: style.writingMode,
        borderWidth: style.borderWidth,
        borderStyle: style.borderStyle,
        borderImage: style.borderImage,
        borderImageSlice: style.borderImageSlice,
        letterSpacing: style.letterSpacing,
        padding: props.layer.style.padding
    }

    if (localState.borderRadius === "" || localState.borderRadius === undefined) {
        delete localState.borderRadius
        localState = {
            ...localState,
            borderTopRightRadius: props.layer.style.borderTopRightRadius,
            borderBottomRightRadius: props.layer.style.borderBottomRightRadius,
            borderTopLeftRadius: props.layer.style.borderTopLeftRadius,
            borderBottomLeftRadius: props.layer.style.borderBottomLeftRadius,
        }
    }

    const handleClick = (layerIndex) => {
        dispatch(updateLayerStatus(layerIndex, props.selectedSlideIndex))
    }

    const handleAnimEnd = () => {
        dispatch(endAnimation(props.selectedSlideIndex, props.index))
    }

    return (
        <Rnd
            key={props.index}
            style={{ boxShadow: style.boxShadow, }}
            className={`${props.layer.isSelected ? "myborder-layer" : ""} text-layer-box`}
            enableResizing={props.layer.isSelected ? true : false}
            disableDragging={props.layer.isSelected ? false : true}
            onClick={() => handleClick(props.index)}
            default={{ x: 0, y: 0, width: 100, height: 100 }}
            size={{ width: style.width, height: style.height }}
            position={{ x: style.left, y: style.top }}
            onDragStop={(e, d) => {
                setStyle({ ...style, left: d.x, top: d.y })
                props.handleRePosition(d.y, d.x, props.index)
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                setStyle(
                    {
                        ...style,
                        width: ref.style.width,
                        height: ref.style.height,
                        top: position.y,
                        left: position.x
                    }
                );
                props.handleResize(ref.style.width, ref.style.height, position.x, position.y, props.index)
            }}
            bounds=".vid-poster"
        >
            <div
                key={props.index}
                className={`animate__animated ${props.layer.animation.playAnimation ? props.layer.animation.name : ""}  css-for-svgs`}
                style={props.layer.type === "button" ? {} : localState}
                onAnimationEnd={() => handleAnimEnd()}
            >
                {props.layer.type === "button" ?
                    <button
                        style={localState}
                        className="button-hw"
                    >
                        {props.layer.src}
                    </button>
                    :
                    props.layer.type === "location" ?
                        <>
                            <i className="fas fa-map-marker-alt mr-2" style={{ color: "red", fontSize: localState.fontSize + 5 }} />{dataVal}
                        </>
                        :
                        dataVal}
            </div>
        </Rnd>
    )
}

export default TextLayer