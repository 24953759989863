import React, { useState } from 'react'
import { IoCopyOutline } from "react-icons/io5";
import { GrPrevious, GrNext } from "react-icons/gr";
import { AiOutlinePlus } from "react-icons/ai";
import { FiTrash2, FiPlayCircle, FiStopCircle } from "react-icons/fi";
import { useDispatch } from 'react-redux';
import { addDuplicteSlide, addSlide, animationPlay, deleteSlide, updateSlideChange } from '../../../actions/storyAction';
import { setAlert } from '../../../actions/alert';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

let ai = false, bg = false
const PageActions = ({ story, slides, selectedSlide, selectedSlideIndex, setPlay, play }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [membership, setMembership] = useState([])
    const [toggle, setToggle] = useState(false)
    const [loader, setLoader] = useState({
        addBefore: false,
        addAfter: false,
        duplicateLoader: false,
        delete: false
    })
    let slideLength = 15
    const handleSlideChange = (type) => {
        if (type === "pre") {
            if (selectedSlideIndex !== 0) {
                dispatch(updateSlideChange(selectedSlideIndex, type))
            }
        } else {
            if (selectedSlideIndex < slides.length - 1) {
                dispatch(updateSlideChange(selectedSlideIndex, type))
            }
        }
    }

    const handleAddSlide = (type) => {
        if (membership.includes("unlimited") || membership.includes("unlimitedlite") || membership.includes("bundle")) {
            slideLength = 30
        }
        if (slideLength >= slides.length + 1) {
            if (type === "before") {
                setLoader({
                    ...loader,
                    addBefore: true
                })
                dispatch(addSlide(selectedSlideIndex, type, selectedSlide, setLoader, loader))
            } else {
                setLoader({
                    ...loader,
                    addAfter: true
                })
                dispatch(addSlide(selectedSlideIndex, type, selectedSlide, setLoader, loader))
            }
        }

    }

    const handleDeleteSlide = () => {
        let obj = {
            slideId: selectedSlide.id,
            storyId: selectedSlide.storyId
        }
        if (slides.length > 1) {
            setLoader({
                ...loader,
                delete: true
            })
            dispatch(deleteSlide(selectedSlideIndex, obj, setLoader, loader))
        } else {
            swal('Oops..', 'You cannot delete the last slide');
        }
    }

    const handlePlay = () => {
        setToggle(true)
        let aiObj = selectedSlide.audio
        let bgObj = story.bg_audio
        if (aiObj.enable && ai === false) {
            ai = new Audio(aiObj.src)
            ai.volume = aiObj.volume / 100
            ai.play()
        }
        if (bgObj.enable && bg === false) {
            bg = new Audio(bgObj.source)
            bg.volume = bgObj.volume / 100
            bg.play()
        }
        dispatch(animationPlay(selectedSlideIndex))
    }

    const handleStop = () => {
        setToggle(false)
        if (ai) {
            ai.pause()
            ai = false
        }
        if (bg) {
            bg.pause()
            bg = false
        }
    }

    const handleDuplicate = () => {
        if (membership.includes("unlimited") || membership.includes("unlimitedlite") || membership.includes("bundle")) {
            slideLength = 30
        }
        if (slideLength >= slides.length + 1) {
            setLoader({
                ...loader,
                duplicateLoader: true
            })
            dispatch(addDuplicteSlide(selectedSlide, selectedSlideIndex , setLoader, loader,))
        }
    }

    useEffect(() => {
        if (auth.user) {
            setMembership(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        return () => {
            if (ai) {
                ai.pause()
                ai = false
            }
            if (bg) {
                bg.pause()
                bg = false
            }
        };
    }, []);

    return (
        <>
            <li className="toottip-enabled">
                <button
                    onClick={() => handleSlideChange("pre")}
                >
                    <GrPrevious />
                </button>
                <span className="toolTip">Previous</span>
            </li>

            <li className="toottip-enabled">
                <button
                    onClick={() => handleAddSlide("before")}
                >
                    {loader.addBefore ?
                        <i className="fa fa-spinner fa-spin" style={{ color: "#000000", fontSize: "20px" }} />
                        :
                        <AiOutlinePlus />}
                </button>
                <span className="toolTip">Add Page Before</span>
            </li>

            <li className="toottip-enabled">
                <button
                    onClick={() => handleDeleteSlide()}
                >
                    {loader.delete ? <i className="fa fa-spinner fa-spin" style={{ color: "#000000", fontSize: "20px" }} /> :
                        <FiTrash2 />
                    }
                </button>
                <span className="toolTip">Delete Page</span>
            </li>
            {toggle ?
                <li className="toottip-enabled">
                    <button
                        onClick={() => handleStop()}
                    >
                        <FiStopCircle />
                    </button>
                    <span className="toolTip">Stop</span>
                </li> :
                <li className="toottip-enabled">
                    <button
                        onClick={() => handlePlay()}
                    >
                        <FiPlayCircle />
                    </button>
                    <span className="toolTip">Play</span>
                </li>
            }
            <li className="toottip-enabled">
                <button
                    onClick={() => handleDuplicate()}
                >
                    {loader.duplicateLoader ?
                        <i className="fa fa-spinner fa-spin" style={{ color: "#000000", fontSize: "20px" }} />
                        :
                        <IoCopyOutline />}
                </button>
                <span className="toolTip">Duplicate Page</span>
            </li>

            <li className="toottip-enabled">
                <button
                    onClick={() => handleAddSlide("after")}
                >
                    {loader.addAfter ?
                        <i className="fa fa-spinner fa-spin" style={{ color: "#000000", fontSize: "20px" }} />
                        :
                        <AiOutlinePlus />}
                </button>
                <span className="toolTip">Add Page After</span>
            </li>

            <li className="toottip-enabled">
                <button
                    onClick={() => handleSlideChange("next")}
                >
                    <GrNext />
                </button>
                <span className="toolTip">Next</span>
            </li>
        </>
    )
}

export default PageActions