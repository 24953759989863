const initialState = {
    totalStories: 0,
    savedTemplate: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "ADD_TOTAL_STORY":
            return {
                ...state,
                totalStories: action.payload
            }

        case "FETCH_TEMPLATES":
            return {
                ...state,
                savedTemplate: action.payload
            }

        default:
            return state

    }
}