import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
const UpgradesMenu = (props) => {
   const auth = useSelector(state => state.auth)
   const [membership, setMembership] = useState([])

   useEffect((props) => {
      if (auth.user) {
         setMembership(auth.user.membership.split("__"))
      }
   }, [auth.user])
   return (
      <>
         <div className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            {membership.includes("business") || membership.includes("bundle") ? <>
               <a className="nav-link" id="v-pills-wlrebranding-tab" data-toggle="pill"
                  href="#v-pills-wlrebranding" role="tab" aria-controls="v-pills-wlrebranding"
                  aria-selected="false">Agency Rebranding</a>
               {/* <a className="nav-link" id="v-pills-udamy-tab" data-toggle="pill" href="#v-pills-udamy"
                  role="tab" aria-controls="v-pills-udamy" aria-selected="false">Acadeable</a> */}
               <a className="nav-link" id="v-pills-reseller-tab" data-toggle="pill" href="#v-pills-reseller"
                  role="tab" aria-controls="v-pills-reseller" aria-selected="false">Reseller License</a>
               <a className="nav-link" id="v-pills-business-tab" data-toggle="pill" href="#v-pills-business"
                  role="tab" aria-controls="v-pills-business" aria-selected="false">Business Finder</a>
               <a className="nav-link" id="v-pills-videocommercial-tab" data-toggle="pill"
                  href="#v-pills-videocommercial" role="tab" aria-controls="v-pills-videocommercial"
                  aria-selected="false">Video Commercial</a>
               <a className="nav-link" id="v-pills-agency-tab" data-toggle="pill" href="#v-pills-agency"
                  role="tab" aria-controls="v-pills-agency" aria-selected="false">Agency Website</a>
               <a className="nav-link" id="v-pills-webhosting-tab" data-toggle="pill"
                  href="#v-pills-webhosting" role="tab" aria-controls="v-pills-webhosting"
                  aria-selected="false">Web Hosting</a>
               <a className="nav-link" id="v-pills-client-tab" data-toggle="pill" href="#v-pills-client"
                  role="tab" aria-controls="v-pills-client" aria-selected="false">Client Contract</a>
               <a className="nav-link" id="v-pills-dfylead-tab" data-toggle="pill" href="#v-pills-dfylead"
                  role="tab" aria-controls="v-pills-dfylead" aria-selected="false">DFY Lead Magnets</a>
               <a className="nav-link" id="v-pills-fbtemplate-tab" data-toggle="pill"
                  href="#v-pills-fbtemplate" role="tab" aria-controls="v-pills-fbtemplate"
                  aria-selected="true">FB Templates</a>
            </>
               : ""}

            {membership.includes("masterclass") || membership.includes("bundle") ?
               <>
                  <a className="nav-link" id="v-pills-Training-tab" data-toggle="pill"
                     href="#v-pills-Training" onClick={()=> props.setShow(true)} role="tab" aria-controls="v-pills-Training"
                     aria-selected="false">Masterclass</a>
                  {/* <a className="nav-link" id="v-pills-social-tab" data-toggle="pill" href="#v-pills-social"
                     role="tab" aria-controls="v-pills-social" aria-selected="false">Social Media Assets</a> */}
               </>
               : ""}



            {/* <a className="nav-link" id="v-pills-vidoeflix-tab" data-toggle="pill" href="#v-pills-vidoeflix"
                           role="tab" aria-controls="v-pills-vidoeflix" aria-selected="false">Video Flix</a> */}

            {/* <a className="nav-link" id="v-pills-dfycourse-tab" data-toggle="pill" href="#v-pills-dfycourse"
                           role="tab" aria-controls="v-pills-dfycourse" aria-selected="false">DFY Course</a> */}




            {/* <a className="nav-link" id="v-pills-videopromo-tab" data-toggle="pill"
               href="#v-pills-videopromo" role="tab" aria-controls="v-pills-videopromo"
               aria-selected="false">Video Promos</a> */}



            {/* <a className="nav-link" id="v-pills-dfyarticles-tab" data-toggle="pill"
               href="#v-pills-dfyarticles" role="tab" aria-controls="v-pills-dfyarticles"
               aria-selected="false">DFY Articles</a> */}
            {/* <a className="nav-link" id="v-pills-affiliate-tab" data-toggle="pill"
               href="#v-pills-affiliate" role="tab" aria-controls="v-pills-afftrack_affiliate"
               aria-selected="false">Afftrack Affiliate Approval</a>
            <a className="nav-link" id="v-pills-youtube-tab" data-toggle="pill"
               href="#v-pills-youtube" role="tab" aria-controls="v-pills-youtube"
               aria-selected="false">YouTube Keyword Research</a>
            <a className="nav-link" id="v-pills-intro-outro-tab" data-toggle="pill"
               href="#v-pills-intro-outro" role="tab" aria-controls="v-pills-intro-outro"
               aria-selected="false">Intro/Outro Creater</a>
            <a className="nav-link" id="v-pills-Social-Traffic-Generator-tab" data-toggle="pill"
               href="#v-pills-Social-Traffic-Generator" role="tab" aria-controls="v-pills-Social-Traffic-Generator"
               aria-selected="false">Social Traffic Generator</a> */}

         </div>

      </>
   )
}

export default UpgradesMenu;