import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Background from './background/Background';
import Template from './templates/Template';
import Text from './Text';
import Elements from './elements/Elements';
import AddOns from './AddOns';
import MediaLibrary from './MediaLibrary';
import Sidebar from "./Sidebar";
import Audio from './audio/Audio';
import Settings from './settings/Settings';


const EditorLeft = ({ storyData }) => {
    const selectedSlide = storyData.slides.find(({ isSelected }) => isSelected === true)
    const selectedSlideIndex = storyData.slides.findIndex(({ isSelected }) => isSelected === true)
    return (
        <div className="editLeft">
            <Tab.Container defaultActiveKey="backGround">
                <Sidebar />
                <div className="tabContN">
                    <Tab.Content className="colTabCont">
                        <Background
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                        <Template
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                        <Text
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                        <Elements
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                        <Audio
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                        <MediaLibrary
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                        <AddOns
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                        <Settings
                            selectedSlide={selectedSlide}
                            selectedSlideIndex={selectedSlideIndex}
                        />
                    </Tab.Content>
                </div>
            </Tab.Container >
        </div >
    )
}

export default EditorLeft