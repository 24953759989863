import React, {useEffect, useState} from "react";
import Navbar from "../Navbar";
import HelpContent from "./HelpContent";
import HelpVideos from "./HelpVideos";
import axios from "axios";
import {useDispatch} from "react-redux";
import {addArticles, addVideos} from "../../actions/supportAction";
import Footer from "../Footer";
import CustomerSupport from "./CustomerSupport";
import TitleBar from "../TitleBar";

const HelpSupport = () => {

    const dispatch = useDispatch();

    const [loadArticles, setLoadArticles] = useState(false);
    const [loadVideos, setLoadVideos] = useState(false);

    const fetchVideos = async () => {
        setLoadVideos(true);
        await axios({
            method: "POST",
            url: "https://adminserver.reelapps.io/api/fetch-videos",
            data: JSON.stringify({app_id: 44}),
            headers: {'Content-Type' : 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(addVideos(res.data.data));
            }
            setLoadVideos(false);
        }).catch(err=>{
            setLoadVideos(false);
        })

    }

    const fetchArticles = async () => {
        setLoadArticles(true);
        await axios({
            method: "POST",
            url: "https://adminserver.reelapps.io/api/fetch-article",
            data: JSON.stringify({app_id: 44}),
            headers: {'Content-Type' : 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(addArticles(res.data.data));
            }
            setLoadArticles(false);
        }).catch(err=>{
            setLoadArticles(false);
        })

    }


    useEffect(()=>{
        fetchVideos();
        fetchArticles();
    },[])


    return(
        <>
            <TitleBar title="Help & Support"/>
            <Navbar/>
            
            <HelpContent loadArticles={loadArticles} />
            <HelpVideos loadVideos={loadVideos}/>
            <CustomerSupport/>

            <Footer/>
        </>
    )
}

export default HelpSupport;