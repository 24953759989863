import React from 'react'
import Tab from 'react-bootstrap/Tab';
import { useDispatch } from 'react-redux';
import { removeAnimation, removeAnimationDuration, removeAnimDelay, updateAnimation, updateAnimDelay, updateAnimDuration } from '../../../actions/storyAction';

const Animation = ({ selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const anim = [
        {
            name: "animate__backOutDown",
            ampName: "drop",
            delay: "0",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__fadeIn",
            ampName: "fade-in",
            delay: "0",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__fadeInUpBig",
            delay: "0",
            ampName: "fly-in-bottom",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__fadeInLeftBig",
            delay: "0",
            ampName: "fly-in-left",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__fadeInRightBig",
            delay: "0",
            ampName: "fly-in-right",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__fadeInDownBig",
            delay: "0",
            ampName: "fly-in-top",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__pulse",
            ampName: "pulse",
            delay: "0s",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__rotateInDownLeft",
            ampName: "rotate-in-left",
            delay: "0",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__rotateInDownRight",
            ampName: "rotate-in-right",
            delay: "0",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__rotateIn",
            ampName: "twirl-in",
            delay: "0",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__slideInLeft",
            ampName: "whoosh-in-left",
            delay: "0",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__slideInRight",
            ampName: "whoosh-in-right",
            delay: "0",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__zoomIn",
            ampName: "zoom-in",
            delay: "0",
            duration: "0.5s",
            playAnimation: true
        },
        {
            name: "animate__zoomOut",
            ampName: "zoom-out",
            delay: "0",
            duration: "0.5s",
            playAnimation: true
        },
    ]


    const onInputAnim = (e) => {
        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (selectedLayerIndex !== -1) {
            if (parseInt(e.target.value) !== -1) {
                dispatch(updateAnimation(anim[parseInt(e.target.value)], selectedSlideIndex, selectedLayerIndex))
            } else {
                dispatch(removeAnimation(selectedSlideIndex, selectedLayerIndex))
            }
        }
    }

    const onInputDuration = (e) => {
        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (selectedLayerIndex !== -1) {
            if (e.target.value !== "") {
                dispatch(updateAnimDuration(e.target.value, selectedSlideIndex, selectedLayerIndex))
            } else {
                dispatch(removeAnimationDuration(selectedSlideIndex, selectedLayerIndex))
            }
        }

    }

    const onInputDelay = (e) => {
        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (selectedLayerIndex !== -1) {
            if (parseInt(e.target.value) >= 0.5) {
                dispatch(updateAnimDelay(e.target.value, selectedSlideIndex, selectedLayerIndex))
            } else {
                dispatch(removeAnimDelay(selectedSlideIndex, selectedLayerIndex))
            }
        }
    }
    return (
        <Tab.Pane eventKey="animationTab">

            <div className="scrollDiv scrollDiv-6">

                <div className="select-your-color">
                    <p className="innerHead">Effect</p>
                    <div className="select-your-color-opt">
                        <select
                            className="inpField text-capitalize"
                            name="effect"
                            id=""
                            onChange={(e) => onInputAnim(e)}>
                            <option value={-1}>Select Animation</option>
                            {anim.map((curElem, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={index}
                                        className="text-capitalize"
                                    >
                                        {curElem.ampName}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="select-your-color">
                    <p className="innerHead">Duration (s)</p>
                    <div className="select-your-color-opt">
                        <input
                            type="number"
                            step={0.5}
                            defaultValue={0.5}
                            min={0.5}
                            className="inpField"
                            onChange={(e) => onInputDuration(e)}
                        />

                    </div>
                </div>
                {/* <div className="select-your-color">
                    <p className="innerHead">Sequence (Play On)</p>
                    <div className="select-your-color-opt">
                        <select className="inpField" name="sequence" id="" onChange={(e) => onInputChange(e)}>
                            <option value="onLoad">On Load</option>
                        </select>
                    </div>
                </div> */}
                <div className="select-your-color">
                    <p className="innerHead">Start Delay</p>
                    <div className="select-your-color-opt">
                        <input
                            type="number"
                            step={0.5}
                            min={0.5}
                            defaultValue={0.5}
                            onChange={(e) => onInputDelay(e)}
                            className="inpField"
                        />
                    </div>
                </div>
            </div>

        </Tab.Pane>
    )
}

export default Animation