const initialState = {
    token: localStorage.getItem('token'),
    loginText: 'Login',
    isAuthenticated: localStorage.getItem('token') ? true: false,
    user: false,
}

export default function (state = initialState, action){
    switch (action.type){
        case 'LOGIN_USER':
            localStorage.setItem('token', action.payload.token)
            return{
                ...state,
                loginText: 'Login',
                isAuthenticated: true,
                token: action.payload.token,
                user: action.payload.user,
            }

        case 'LOGOUT' :
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                loader: false,
                token: false,
                user: false
            }

        case 'CHANGE_USER_IMAGE':
            state.user.image = action.payload;
            return {
                ...state
            }

        case 'CHANGE_USER_NAME':
            state.user.name = action.action;
            return {
                ...state
            }

        case 'CHANGE_USER_EMAIL':
            state.user.email = action.action;
            return {
                ...state
            }

        case 'LOAD_USER_DATA':
            return {
                ...state,
                user: action.payload
            }

        default:
            return state;

    }
}