import React, { useEffect, useState } from "react";
import Preview from "../../editor/Preview";
import LayerActions from "./LayerActions";
import PageActions from "./PageActions";
import { Nav, Tab } from 'react-bootstrap'
import { useDispatch } from "react-redux";
import { activeSlide, addUpdateState, saveStory, unmountUndoRedo } from '../../../actions/storyAction'
import { useSelector } from "react-redux";

const EditorMid = ({ storyData }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const selectedSlide = storyData.slides.find(({ isSelected }) => isSelected === true)
    const selectedSlideIndex = storyData.slides.findIndex(({ isSelected }) => isSelected === true)

    const handleActiveSlide = (index) => {
        if (selectedSlideIndex !== index) {
            dispatch(activeSlide(index, selectedSlideIndex))
        }
    }

    useEffect(() => {
        if (storyData) {
            dispatch(saveStory(storyData, auth.token)) //save story on every change
        }
    }, [storyData]);

    return (
        <div className="editMid">
            <div className="mainCanvas">
                <div className="editArea">
                    <div className="editNav">
                        <ul>
                            <LayerActions
                                slides={storyData.slides}
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}
                            />
                        </ul>
                    </div>


                    <Tab.Container id="left-tabs-example" defaultActiveKey={`slide ${selectedSlideIndex + 1}`}>
                        <Nav className="slideNav colNav-fill">
                            {storyData.slides.map((curElem, index) => {
                                return (
                                    <Nav.Item
                                        key={index}
                                        onClick={() => handleActiveSlide(index)}
                                        className={index === selectedSlideIndex ? "myActiveNav" : ''}
                                    >
                                        <Nav.Link eventKey={`slide ${index + 1}`} >
                                            Slide {index + 1}
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            })}
                        </Nav>

                        {/* MAIN PREVIEW TO HANDLE */}

                        <Preview
                            selectedSlideIndex={selectedSlideIndex}
                            selectedSlide={selectedSlide}
                            storyData={storyData}
                        />

                    </Tab.Container>



                    <div className="controlNav">
                        <ul>
                            <PageActions
                                story={storyData}
                                slides={storyData.slides}
                                selectedSlide={selectedSlide}
                                selectedSlideIndex={selectedSlideIndex}
                            />
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EditorMid