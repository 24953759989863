import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateSeoDesc, updateSeoImgUrl, updateSeoKey, updateSeoTitle } from '../../actions/publishAction'

const Seo = () => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        title: "",
        seoKeyword: "",
        description: "",
        imageUrl: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
        if (name === "title") {
            dispatch(updateSeoTitle(value))
        }
        else if (name === "seoKeyword") {
            dispatch(updateSeoKey(value))
        }
        else if (name === "description") {
            dispatch(updateSeoDesc(value))
        }
        else if (name === "imageUrl") {
            dispatch(updateSeoImgUrl(value))
        }
    }
    return (
        <div className="formWrap">
            <div className="profileBlock">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group form-group-alt mt-0">
                            <label>Title</label>
                            <input
                                className="form-control"
                                placeholder="SEO Title"
                                type="text"
                                name="title"
                                value={state.title}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group form-group-alt mt-0">
                            <label>Keywords</label>
                            <input
                                className="form-control"
                                placeholder="SEO Keywords"
                                type="text"
                                name="seoKeyword"
                                value={state.seoKeyword}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group form-group-alt">
                    <label>Description</label>
                    <textarea
                        className="form-control forTextr"
                        placeholder="SEO Description"
                        name="description"
                        value={state.description}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className="form-group form-group-alt">
                    <label>Image URL</label>
                    <input
                        className="form-control"
                        placeholder="Image URL"
                        type="text"
                        name="imageUrl"
                        value={state.imageUrl}
                        onChange={(e) => handleChange(e)}

                    />
                </div>
            </div>
        </div>
    )
}

export default Seo