import React, { useState, useEffect } from 'react'
import BackgroundPreview from './BackgroundPreview'
import MediaLayer from './MediaLayer'
import TextLayer from './TextLayer'
import '../../temp.css'
import { useSelector, useDispatch } from 'react-redux'
import { rePositionLayer, resizeLayer, updateLastAction, updatePresent } from '../../actions/storyAction'
import PollsLayer from './PollsLayer'


const Preview = ({ selectedSlide, selectedSlideIndex, play }) => {
    const story = useSelector(state => state.story)
    const dispatch = useDispatch()
    let actionArr = ["UNDO", "REDO", "SLIDE_BACK", "SLIDE_FORE", "ACTIVE_SLIDE", "PLAY_ANIMATION", "END_ANIMATION", "UPDATE_INITIAL_MEDIA_ATT", "ADD_STORY_DATA", "DELETE_SLIDE"]
    let allowTypeMedia = ["image", "video", "gif", "emoji"]


    const handleResize = (width, height, left, top, layerIndex, type, renderWidth, renderHeight, plusInLeft, plusInRight) => {
        width = parseInt(width.replace("px", ""))
        height = parseInt(height.replace("px", ""))
        dispatch(resizeLayer(width, height, left, top, layerIndex, selectedSlideIndex, type, renderWidth, renderHeight, plusInLeft, plusInRight))
    }

    const handleRePosition = (top, left, layerIndex) => {
        dispatch(rePositionLayer(top, left, layerIndex, selectedSlideIndex))
    }


    useEffect(() => {
        if (actionArr.includes(story.lastAction)) {
            dispatch(updateLastAction(selectedSlide))
        } else {
            dispatch(updatePresent(selectedSlide))
        }
    }, [selectedSlide])




    return (
        <div className="vid-poster full-width mt-4">
            <div className="video-container ">
                <BackgroundPreview
                    background={selectedSlide.background}
                    selectedSlideIndex={selectedSlideIndex}
                    selectedSlide={selectedSlide}
                />
                {
                    selectedSlide.layers.length > 0 ?
                        selectedSlide.layers.map((curElem, index) => {
                            if (allowTypeMedia.includes(curElem.type)) {
                                return (
                                    <MediaLayer
                                        index={index}
                                        layer={curElem}
                                        play={play}
                                        selectedSlideIndex={selectedSlideIndex}
                                        selectedSlide={selectedSlide}
                                        handleResize={handleResize}
                                        handleRePosition={handleRePosition}
                                    />
                                )
                            } else if (curElem.type === "polls") {
                                return (
                                    <PollsLayer
                                        index={index}
                                        layer={curElem}
                                        play={play}
                                        selectedSlideIndex={selectedSlideIndex}
                                        selectedSlide={selectedSlide}
                                        handleResize={handleResize}
                                        handleRePosition={handleRePosition}
                                    />
                                )
                            }
                            else {
                                return (
                                    <TextLayer
                                        index={index}
                                        play={play}
                                        layer={curElem}
                                        selectedSlide={selectedSlide}
                                        selectedSlideIndex={selectedSlideIndex}
                                        handleResize={handleResize}
                                        handleRePosition={handleRePosition}
                                    />
                                )
                            }
                        })
                        : ''
                }
            </div>
        </div>

    )
}
export default Preview;
