import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';

import modalCloseIcon from '../../images/modal-close.png';

import Alert from "../Alert"

import { VscPlayCircle } from "react-icons/vsc";
import { BsArrowRight } from "react-icons/bs";

import CreateStoryModule from "../CreateVideos/CreateStoryModule";
import { getTempData } from "../../actions/storyAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const DashboardHead = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrandData = useSelector(state => state.rebrand.data);
    const totalStories = useSelector(state => state.extra.totalStories)

    const [membership, setMembership] = useState([])
    const [totalTemp, setTotalTemp] = useState([])
    const [loader, setLoader] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const fetchTotalTempSize = () => {
        setLoader(true)
        dispatch(getTempData(setTotalTemp, setLoader, membership))
    }

    useEffect(() => {
        if (auth.user) {
            setMembership(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        if (membership.length > 0) {
            fetchTotalTempSize()
        }
        return () => {
            setTotalTemp([])
        }
    }, [membership])

    return (
        <>
            <Alert />
            <section className="hero-banner">
                <div className="hero-video">
                    <video src="https://reeelapps-app.s3.us-west-2.amazonaws.com/StoryReel2_dashboard_bg+video.mp4" muted autoPlay loop width={"100%"} />
                </div>
                <div className="hero-banner-txt">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="hero-banner-left">
                                    <h2>WELCOME TO</h2>
                                    {rebrandData ? <h3><span>{rebrandData.name}</span></h3> :
                                        <h3><span>STORY</span>REEL</h3>}
                                    <p>Instantly create short brandable videos using fully-customizable <br /> templates optimized for social media, ads, and more.</p>
                                    <a onClick={handleShow} className="demoLink"><i class="fa fa-play" aria-hidden="true"></i> Watch Now</a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="hero-banner-right">
                                    {/* <a onClick={handleShow2} className="bannerLink"> */}
                                    <Link className="bannerLink">
                                        <div className="bannerLink-left">
                                            <span className="countN">{loader ? <i className="fa fa-spinner fa-spin " style={{ fontSize: "30px" }} /> : totalTemp.length}</span>
                                            <span>Templates Available</span>
                                        </div>
                                        <div className="bannerLink-right"><BsArrowRight /></div>
                                    </Link>
                                    <Link to="/stories" className="bannerLink">
                                        <div className="bannerLink-left">
                                            <span className="countN">{totalStories}</span>
                                            <span>Stories Created</span>
                                        </div>
                                        <div className="bannerLink-right"><BsArrowRight /></div>
                                    </Link>
                                    {auth.user.is_client_account !== "1" ?
                                        <a onClick={handleShow2} className="bannerLink">
                                            <div className="bannerLink-left">
                                                <span className="countN"><VscPlayCircle /></span>
                                                <span>Create Stories</span>
                                            </div>
                                            <div className="bannerLink-right"><BsArrowRight /></div>
                                        </a> : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>



            <Modal className="VideoModal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                            <iframe src="https://player.vimeo.com/video/763349082?h=28c219f82c?loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false" allowfullscreen="" allow="autoplay,fullscreen,picture-in-picture" title="Player for CourseReel Intro" data-ready="true" tabindex="-1"></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <CreateStoryModule
                show={show2}
                handleClose={handleClose2}
            />
        </>
    )
}

export default DashboardHead;