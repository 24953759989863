import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { addRebrandData, removeRebrandData } from "../../actions/rebrandAction";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";

const Rebranding = () => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(rebrandData);
    const [loader, setLoader] = useState(false);

    const [fileData, setFileData] = useState('');
    const [name, setName] = useState('');

    // Sweetalert
    const [button, setButton] = useState('Delete');
    const [deleteData, setDeletedData] = useState({
        id: ''
    });
    const [showDelete, setShowDelete] = useState(false);

    const onConfirm = async () => {
        setButton('Deleting...')
        let options = {
            headers: {
                'Authorization': auth.token
            }
        }

        await axios.post(`${baseURL}delete-wl`, deleteData, options).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(removeRebrandData(auth.token))
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })

        setShowDelete(false);
        setButton('Delete')
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const removeWl = (id) => {
        setDeletedData({ ...deleteData, id: id });
        setShowDelete(true);
    }

    const onInputFile = (e) => {
        setFileData(e.target.files[0]);
        dispatch(setAlert("Image Upload Successfully", 'success'));
    }

    const onInputName = (e) => {
        setName(e.target.value);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG') {
            if (fileData.size < 5000000) {
                setLoader(true);

                let formData = new FormData();
                formData.append('name', name);
                formData.append('logo', fileData);

                dispatch(addRebrandData(formData, setLoader));

            } else {
                swal("Oops!", "Max Uploaded Size for Image 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }




    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return (
        <>
            <Alert />
            <div className="upgrade-content-n">
                <h3>Rebranding</h3>
                <form className="row  mt-5 bg-table justify-content-center align-items-center" method="post" onSubmit={(e) => onFormSubmit(e)}>
                    <div className="col-11 p-0" style={{ height: "200px", backgroundColor: "rgb(54, 56, 57)", border: "2px dashed white" }}>
                        <div style={{ height: "100%", width: "100%", cursor: "pointer" }} >
                            <div className="upload-btn-wrapper">
                                <div className="d-flex mt-4 justify-content-center align-items-center flex-column">
                                    <button
                                        style={{
                                            backgroundColor: "rgb(54, 56, 57)",
                                            color: "white",
                                            border: "none",
                                            fontSize: "1rem"
                                        }}
                                    >
                                        Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed) <br /> (210x34) Pixels recommended
                                    </button>
                                    <i
                                        className="fas fa-cloud-upload-alt"
                                        aria-hidden="true"
                                        style={{
                                            display: "block",
                                            color: "white",

                                            fontSize: "2rem",
                                            paddingTop: 10
                                        }}
                                    />
                                </div>
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    accept='image/*'
                                    name="file"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        padding: 0,
                                        cursor: "pointer"
                                    }}
                                    onChange={(e) => onInputFile(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12 mt-4 p-1">
                        <div className="form-group form-group-mb">
                            <label className="input-label text-white ">Brand Name </label>
                            <input
                                style={{ backgroundColor: "rgb(54, 56, 57)", color: "white" }}
                                className="form-control input-dark-accnt brandonfocus personal-info  w-100"
                                type="text"
                                name="firstName"
                                placeholder="Enter your brand Name"
                                required
                                onChange={(e) => onInputName(e)}
                            />
                        </div>
                    </div>
                    <div className="col col-md-12 text-center mt-3">
                        <button
                            type="submit"
                            className="demoLink mt-0"
                            disabled={loader}
                        >
                            {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Save
                        </button>
                    </div>
                    {
                        rebrand.data !== false ?
                            <button
                                type="button" onClick={(e) => removeWl(rebrand.data.id)}
                                className="demoLink mt-2">
                                Remove Rebranding
                            </button>
                            : ''
                    }
                </form>
            </div>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this Rebranding
            </SweetAlert>
        </>
    )
}

export default Rebranding;